export const PATHS = {
  WELCOME: "/",
  HOME: "/home",
  LOGIN: "/login",
  ALL: "*",

  WHO_WE_ARE: "/who-we-are",
  TEAM: "/team",

  /* OUR_DEALS */
  DEALS: "/deals",
  CREATE_DEAL: "/deals/create",
  DEAL_DETAIL: "/deals/:id",

  /* ESC_IMPACT */
  IMPACT: "/impact",
  CREATE_IMPACT: "/impact/create",
  IMPACT_DETAIL: "/impact/:id",

  IR_INFORMATION: "/ir-information",
  CONTACT_US: "/contact-us",

  CUSTOMER: "/customer",
  SUBMIT: "/submit",

  /* ESC_JOINUS */
  JOIN_US: "/join-us",
  CREATE_JOINUS: "/join-us/create",
  JOINUS_DETAIL: "/join-us/:id",

  /* OUR_BUSINESS */
  OUR_BUSINESS: "/our-business",
  BUSINESS_CATEGORY: "/our-business/create",
  BUSINESS_CATEGORY_DETAIL: "/our-business/:id",
};
