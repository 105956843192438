import { Flex, Image, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useState } from "react";
import { AButton, ActionTable, AInput } from "~/components";
import useGlobal from "~hooks/useGlobal";
import { useDeleteMember, useGetTeam } from "~queries";
import { IMember } from "~types";
import MemberModal from "./modal";

const OurTeam = () => {
  const { confirmDelete, message } = useGlobal();
  const { mutate } = useDeleteMember();
  const [fullName, setFullName] = useState("");
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data, refetch } = useGetTeam({ ...pagination, filters: { fullName } });
  const [openModal, setOpenModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [member, setMember] = useState<IMember>({} as IMember);

  const onSearch = (value: string) => {
    setFullName(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete member successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          refetch();
        },
        onError: () => {
          message.error("Delete member failed");
        },
      });
    }
  };
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setMember({} as IMember);
    setIsView(false);
  };

  const onEditMember = (member: IMember, isView: boolean) => {
    setMember(member);
    showModal();
    setIsView(isView);
  };

  const columns: ColumnType<IMember>[] = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      width: 200,
    },
    {
      title: "Display Order",
      dataIndex: "displayOrder",
      align: "center",
      width: 140,
    },
    {
      title: "Profile Picture",
      dataIndex: "avatarUrl",
      align: "center",
      width: 150,
      render: (value) => <Image src={value} alt="avatar" height={60} className="fit-cover" />,
    },
    {
      title: "Role",
      dataIndex: "position",
      width: 350,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "center",
      width: 120,
    },
    {
      title: "Actions",
      width: 170,
      fixed: "right",
      render: (_, record) => (
        <ActionTable
          onView={() => onEditMember(record, true)}
          onEdit={() => onEditMember(record, false)}
          onDelete={() => onDelete(record.id)}
        />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} justify="space-between">
        <AInput span={6} placeholder="Search member by name" type="search" onSearch={onSearch} />
        <AButton iconType="add" onClick={showModal}>
          Create
        </AButton>
      </Flex>
      {openModal && (
        <MemberModal open={openModal} isView={isView} onCancel={closeModal} member={member} />
      )}
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default OurTeam;
