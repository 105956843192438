import request from "~libs/axios";
import { ICreateDeal, IResponse, IDeal, TQueryDeal, TUpdateDealParameter } from "~types";

const END_POINT = "/deals/";

const dealService = {
  getAll(params: TQueryDeal): Promise<IResponse<IDeal>> {
    return request.get(END_POINT, { params });
  },
  get(id: number): Promise<IDeal> {
    return request.get(END_POINT + id);
  },
  create(payload: ICreateDeal) {
    return request.post(END_POINT, payload);
  },
  update({ id, payload }: TUpdateDealParameter) {
    return request.patch(END_POINT + id, payload);
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default dealService;
