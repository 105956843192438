import "./loading.scss";

interface ILoadingProps {
  show?: boolean;
}

const Loading = ({ show }: ILoadingProps) => {
  if (!show) return null;

  return (
    <div className="e-loading">
      <div className="e-loading-inner">
        <div className="e-loading-line-wrap">
          <div className="e-loading-line"></div>
        </div>
        <div className="e-loading-line-wrap">
          <div className="e-loading-line"></div>
        </div>
        <div className="e-loading-line-wrap">
          <div className="e-loading-line"></div>
        </div>
        <div className="e-loading-line-wrap">
          <div className="e-loading-line"></div>
        </div>
        <div className="e-loading-line-wrap">
          <div className="e-loading-line"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
