import request from "~libs/axios";
import { ICreatePage, IPage, IResponse, IUpdatePage, TQueryPage } from "~types";

const END_POINT = "/pages/";

const pageService = {
  getAll(params: TQueryPage): Promise<IResponse<IPage>> {
    return request.get(END_POINT, { params });
  },
  getByPageId(pageId: number): Promise<IPage> {
    return request.get(END_POINT + "by-page-id/" + pageId);
  },
  get(id: number): Promise<IPage> {
    return request.get(END_POINT + id);
  },
  create(payload: ICreatePage) {
    return request.post(END_POINT, payload);
  },
  update({ id, payload }: IUpdatePage) {
    return request.patch(END_POINT + id, payload);
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default pageService;
