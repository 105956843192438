import { MinusCircleOutlined } from "@ant-design/icons";
import { Col, DatePicker, Divider, Flex, Form, Row, Space, Switch } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AButton, AInput, AUpload, Loading } from "~/components";
import ASelect from "~/components/common/a-select";
import { DEAL_TAG, PATHS } from "~constants";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreateDeal, useGetCustomers, useGetDeal, useUpdateDeal } from "~queries";
import { EFundType, ICreateDeal } from "~types";
import { getOptions } from "~utils/funcHelper";
import { getFundByType, groupFunds } from "./deal.helper";
import dayjs from "dayjs";

const DealForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dealId = Number(params.id);
  const [form] = Form.useForm();
  const isShowIPO = Form.useWatch("isShowIpo", form);
  const { message, showError } = useGlobal();
  const { data: customers } = useGetCustomers({ page: 1, limit: 50 });
  const customerOptions = getOptions("name", customers?.data);
  const { data: dealDetail, isFetching } = useGetDeal(params);
  const { mutate: mutateCreate, isPending: loadingCreate } = useCreateDeal();
  const { mutate: mutateUpdate, isPending: loadingUpdate } = useUpdateDeal();
  const { fileId, setFileId, fileList, setFileList, handleUploadChange, loading } = useUploadFile();
  const isEdit = dealDetail && Object.keys(dealDetail).length > 0;
  const action = `${isEdit ? "Update" : "Create New"} Deal`;
  const initialValues = {
    tag: null,
    funding: [{ type: 1 }],
    tickets: [{ type: 2 }],
  };

  const backToList = () => navigate({ pathname: PATHS.DEALS, search: `tab=2` });

  const onSuccess = () => {
    message.success(`${action} successfully`);
    backToList();
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async (values: ICreateDeal) => {
    const payload = {
      ...values,
      thumbnailId: fileId!,
      transferCustomerId: values.transferCustomerId ?? null,
      tag: values.tag ?? null,
      funds: groupFunds(values.funding, values.tickets),
    };

    if (isEdit) {
      mutateUpdate({ id: dealId, payload }, { onSuccess, onError });
    } else {
      mutateCreate(payload, { onSuccess, onError });
    }
  };

  const setValue = () => {
    if (isEdit) {
      form.setFieldsValue({
        ...dealDetail,
        transferCustomerId: dealDetail?.transferCustomer?.id,
        acquisitionCustomerId: dealDetail?.acquisitionCustomer?.id,
        funding: getFundByType(dealDetail?.funds, EFundType.FUNDING),
        tickets: getFundByType(dealDetail?.funds, EFundType.TICKETS),
        ipoDate: dealDetail?.ipoDate ? dayjs(dealDetail?.ipoDate) : null,
      });
      const defaultFile = {
        uid: String(dealDetail?.thumbnail?.id),
        name: dealDetail?.thumbnail?.fileName,
      };
      dealDetail?.thumbnail?.id && setFileList([defaultFile]);
      setFileId(dealDetail?.thumbnail?.id);
    } else {
      form.resetFields();
    }
  };

  useEffect(() => {
    setValue();
  }, [form, isEdit, dealDetail]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  if (isFetching) {
    return <Loading show />;
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <h2 className="mb-20">{action}</h2>
      <Row gutter={[{ xl: 8, xxl: 15 }, 15]}>
        <AInput
          span={12}
          placeholder="Enter deal title"
          formItemProps={{ name: "title", label: "Title", rules: [{ required: true, max: 500 }] }}
        />
        <ASelect
          span={6}
          placeholder="Select a tag"
          options={DEAL_TAG}
          formItemProps={{ name: "tag", label: "Tag" }}
        />
        <Col span={2}>
          <Form.Item name="isHighlight" label="Highlight">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="isSuccessful" label="Successful">
            <Switch />
          </Form.Item>
        </Col>
        <AInput
          span={6}
          placeholder="Enter industry"
          formItemProps={{
            name: "industry",
            label: "Industry",
            rules: [{ max: 100 }],
          }}
        />
        <AInput
          span={6}
          placeholder="Enter area"
          formItemProps={{
            name: "area",
            label: "Area",
            rules: [{ max: 100 }],
          }}
        />
        <AInput
          span={6}
          placeholder="Enter deal type"
          formItemProps={{
            name: "dealType",
            label: "Deal Type",
            rules: [{ max: 100 }],
          }}
        />
        <AInput
          span={6}
          placeholder="Enter instrument type"
          formItemProps={{
            name: "instrumentType",
            label: "Instrument Type",
            rules: [{ max: 100 }],
          }}
        />
        <AUpload
          span={12}
          loading={loading}
          className="dis-flex gap-20"
          fileList={fileList}
          onChange={({ file, fileList }) => handleUploadChange(file, fileList, isEdit)}
          formItemProps={{ name: "thumbnail", label: "Thumbnail", rules: [{ required: true }] }}
        />
        <AInput
          span={24}
          type="textarea"
          formItemProps={{ name: "description", label: "Description" }}
          textAreaProps={{ rows: 7, placeholder: "Enter deal description" }}
        />
        <Col span={12}>
          <Divider orientation="center">Funding Committed</Divider>
          <Form.Item>
            <Form.List name="funding">
              {(fields, { add, remove }) => (
                <Flex vertical gap={15}>
                  <AButton type="default" onClick={() => add({ type: 1 })} iconType="add">
                    Add
                  </AButton>
                  {fields.map((field) => (
                    <Row key={field.key} gutter={[15, 0]}>
                      <AInput
                        span={12}
                        placeholder="Value"
                        formItemProps={{
                          noStyle: true,
                          name: [field.name, "value"],
                        }}
                      />
                      <AInput
                        span={11}
                        placeholder="Currency"
                        formItemProps={{
                          noStyle: true,
                          name: [field.name, "currency"],
                        }}
                      />
                      <MinusCircleOutlined className="fs-20" onClick={() => remove(field.name)} />
                    </Row>
                  ))}
                </Flex>
              )}
            </Form.List>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Divider orientation="center">Min Ticket Size</Divider>
          <Form.Item>
            <Form.List name="tickets">
              {(fields, { add, remove }) => (
                <Flex vertical gap={15}>
                  <AButton type="default" onClick={() => add({ type: 2 })} iconType="add">
                    Add
                  </AButton>
                  {fields.map((field) => (
                    <Row key={field.key} gutter={[15, 0]}>
                      <AInput
                        span={12}
                        placeholder="Value"
                        formItemProps={{
                          noStyle: true,
                          name: [field.name, "value"],
                        }}
                      />
                      <AInput
                        span={11}
                        placeholder="Currency"
                        formItemProps={{
                          noStyle: true,
                          name: [field.name, "currency"],
                        }}
                      />
                      <MinusCircleOutlined className="fs-20" onClick={() => remove(field.name)} />
                    </Row>
                  ))}
                </Flex>
              )}
            </Form.List>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="isShowIpo" label="Show IPO" className="ma-0">
            <Switch />
          </Form.Item>
        </Col>
        {isShowIPO ? (
          <>
            <Divider orientation="left">IPO information</Divider>
            <AInput
              span={6}
              placeholder="Enter listing venue"
              formItemProps={{
                name: "listingVenue",
                label: "Listing Venue",
              }}
            />
            <Col span={6}>
              <Form.Item name="ipoDate" label="IPO Date">
                <DatePicker className="w-full" picker="month" format="MM/YYYY" />
              </Form.Item>
            </Col>
            <AInput
              span={6}
              placeholder="Enter amount Raised"
              formItemProps={{
                name: "amountRaised",
                label: "Amount Raised",
              }}
            />
            <AInput
              span={6}
              placeholder="Enter name of company"
              formItemProps={{
                name: "ipoCompanyName",
                label: "Company Name",
              }}
            />
            <AInput
              span={24}
              type="textarea"
              formItemProps={{ name: "ipoDetails", label: "IPO Details" }}
              textAreaProps={{ rows: 6, placeholder: "Enter Details" }}
            />
          </>
        ) : (
          <>
            <Divider orientation="left">Transferor Company</Divider>
            <ASelect
              span={6}
              placeholder="Select a company"
              options={customerOptions}
              formItemProps={{
                name: "transferCustomerId",
                label: "Transferor Company",
              }}
            />
            <AInput
              span={10}
              formItemProps={{
                name: "transferReason",
                label: "Reason For Transfer",
              }}
            />
            <Divider orientation="left">Transferee Company</Divider>
            <ASelect
              span={6}
              placeholder="Select a company"
              options={customerOptions}
              formItemProps={{
                name: "acquisitionCustomerId",
                label: "Transferee Company",
              }}
            />
            <AInput
              span={10}
              formItemProps={{
                name: "acquisitionPurpose",
                label: "Purpose of The Acquisition",
              }}
            />
          </>
        )}
      </Row>
      <Space className="mt-20">
        <AButton htmlType="submit" loading={loadingUpdate || loadingCreate}>
          Submit
        </AButton>
        <AButton type="default" onClick={backToList}>
          Cancel
        </AButton>
      </Space>
    </Form>
  );
};

export default DealForm;
