import type { AxiosResponse } from "axios";
import { PATHS } from "~constants";
import { IRefreshTokenResponse } from "~types";
import { localCache } from "~utils/cacheHelper";
import { logOut, setCredentials } from "~utils/funcHelper";
import request from "./request";

let isRefreshing = false;
let refreshSubscribers: Function[] = [];

function addSubscriber(callback: Function) {
  refreshSubscribers.push(callback);
}

// Hàm gọi tất cả các callback trong mảng chờ khi token đã được làm mới
function onRefreshed(newToken: string) {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
  isRefreshing = false;
}

const responseHandler = {
  success: (response: AxiosResponse) => {
    return response.data;
  },
  error: async (error: any) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== PATHS.LOGIN &&
      !error.config._retry
    ) {
      error.config._retry = true;
      try {
        if (!isRefreshing) {
          isRefreshing = true;
          const { token, refreshToken }: IRefreshTokenResponse = await request.post(
            "/auth/refresh",
            {
              refreshToken: localCache.get("refreshToken"),
            },
          );
          onRefreshed(token);
          setCredentials(token, refreshToken);
          error.config.headers.Authorization = `Bearer ${token}`;
          return request(error.config);
        }

        return new Promise((resolve) => {
          addSubscriber((token: string) => {
            error.config.headers.Authorization = `Bearer ${token}`;
            resolve(request(error.config));
          });
        });
      } catch {
        logOut();
        window.location.href = "/login";
      }
    }
    return Promise.reject(error.response?.data);
  },
};

export default responseHandler;
