import { IFund } from "~types";
import { isNull } from "~utils/funcHelper";

export const getFundByType = (funds: IFund[], type: IFund["type"]) => {
  if (!funds?.length) return [];
  return funds.filter((fund) => fund.type === type);
};

export const groupFunds = (funding: IFund[], tickets: IFund[]) => {
  const funds = [...funding, ...tickets];
  const filteredFunds = funds.filter((fund) => !isNull(fund.currency) || !isNull(fund.value));
  return filteredFunds;
};
