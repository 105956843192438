import { ArticleList } from "~/components/article";
import { PAGE_ID, PATHS } from "~constants";
import { useGetPageList } from "~queries";

const BusinessCategory = () => {
  const { data } = useGetPageList(
    {
      limit: 50,
      filters: { parentPageId: PAGE_ID.BUSINESS },
    },
    true,
  );

  return (
    <ArticleList
      createPath={PATHS.BUSINESS_CATEGORY}
      detailPath={PATHS.BUSINESS_CATEGORY_DETAIL}
      pageOptions={data?.data}
      parentPageId={PAGE_ID.BUSINESS}
    />
  );
};

export default BusinessCategory;
