import { useQueryClient } from "@tanstack/react-query";
import { Flex, Image, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useState } from "react";
import { AButton, ActionTable, AInput } from "~/components";
import { PAGE_ID } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useDeletePage, useGetPageList } from "~queries";
import { IPage } from "~types";
import { formatDate } from "~utils/dateHelper";
import { getImgUrl } from "~utils/funcHelper";
import BusinessModal from "./business-modal";

const BusinessList = () => {
  const queryClient = useQueryClient();
  const { confirmDelete, message } = useGlobal();
  const { mutate } = useDeletePage();
  const [title, setTitle] = useState("");
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data } = useGetPageList({
    ...pagination,
    filters: { title, parentPageId: PAGE_ID.BUSINESS },
  });
  const [openModal, setOpenModal] = useState(false);
  const [business, setBusiness] = useState<IPage>({} as IPage);

  const onSearch = (value: string) => {
    setTitle(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete business successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          queryClient.invalidateQueries({ queryKey: ["get-page-list"] });
        },
        onError: () => {
          message.error("Delete business failed");
        },
      });
    }
  };
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setBusiness({} as IPage);
  };

  const onEditBusiness = (member: IPage) => {
    setBusiness(member);
    showModal();
  };

  const columns: ColumnType<any>[] = [
    {
      title: "Title",
      dataIndex: "title",
      width: 500,
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnailImg",
      align: "center",
      render: (value) => (
        <Image src={getImgUrl(value?.fileName)} alt="avatar" height={60} className="fit-cover" />
      ),
    },
    {
      title: "Banner",
      dataIndex: "bannerImg",
      align: "center",
      render: (value) => (
        <Image src={getImgUrl(value?.fileName)} alt="banner" height={60} className="fit-cover" />
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "center",
      render: (value) => formatDate(value),
    },
    {
      title: "Actions",
      width: 170,
      fixed: "right",
      render: (_, record) => (
        <ActionTable onEdit={() => onEditBusiness(record)} onDelete={() => onDelete(record.id)} />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} justify="space-between">
        <AInput span={6} placeholder="Search business by title" type="search" onSearch={onSearch} />
        <AButton iconType="add" onClick={showModal}>
          Create
        </AButton>
      </Flex>
      <BusinessModal open={openModal} onCancel={closeModal} business={business} />
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default BusinessList;
