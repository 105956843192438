import request from "~libs/axios";
import { ICredentials, ILoginResponse } from "~types";

const END_POINT = "/auth/";

const authService = {
  login(credentials: ICredentials): Promise<ILoginResponse> {
    return request.post(END_POINT + "email/login", credentials);
  },

  forgotPassword() {
    return request.post("/forgot-password");
  },
};

export default authService;
