import type { FormItemProps, UploadFile, UploadProps } from "antd";
import { Col, Form, message, Upload } from "antd";
import { ALLOWED_TYPES } from "~constants";

import { validateFileType } from "~utils/fileHelper";
import AButton from "../a-button";

interface IAUploadProps extends UploadProps {
  span?: string | number;
  formItemProps?: FormItemProps;
  errorMsg?: string;
  allowedTypes?: string[];
  loading?: boolean;
}

const AUpload = ({
  formItemProps,
  span,
  errorMsg = "is not a png, jpg or jpeg type file",
  allowedTypes,
  loading,
  ...props
}: IAUploadProps) => {
  const beforeUpload = (file: UploadFile) => {
    return new Promise<boolean>((_, reject) => {
      const isAllowedType = validateFileType(file, allowedTypes ?? ALLOWED_TYPES);

      if (!isAllowedType) {
        message.error(`${file.name} ${errorMsg}`);
        return;
      }

      reject(false);
    });
  };

  const children = props.children || (
    <AButton iconType="upload" type="default" loading={loading}>
      Upload
    </AButton>
  );

  return (
    <Col span={span}>
      <Form.Item {...formItemProps} getValueFromEvent={(e) => e && e.fileList}>
        <Upload beforeUpload={beforeUpload} maxCount={1} accept="image/*" {...props}>
          {children}
        </Upload>
      </Form.Item>
    </Col>
  );
};

export default AUpload;
