import { useMutation, useQuery } from "@tanstack/react-query";
import newsService from "~services/newsService";
import { ICreateNews, TQueryNews, TUpdateNewsParameter } from "~types";

export const useGetNews = (params: TQueryNews) =>
  useQuery({
    queryKey: ["get-news", params],
    queryFn: () => newsService.getAll(params),
  });

export const useCreateNews = () =>
  useMutation({
    mutationKey: ["create-news"],
    mutationFn: (payload: ICreateNews) => newsService.create(payload),
  });

export const useUpdateNews = () =>
  useMutation({
    mutationKey: ["update-news"],
    mutationFn: ({ id, payload }: TUpdateNewsParameter) => newsService.update({ id, payload }),
  });

export const useDeleteNews = () =>
  useMutation({
    mutationKey: ["delete-news"],
    mutationFn: (id: number) => newsService.delete(id),
  });
