import { IBaseEntity, IFile, IRquestParams } from "./common.type";

export enum EMediaType {
  INVESTMENT,
  EQUITIES,
  WEALTH,
  FIRMWIDE,
}

export interface ICreateMedia extends IBaseEntity {
  title: string;
  type: EMediaType;
  fileId: number;
}

export interface IMedia extends ICreateMedia, IBaseEntity {
  file: IFile;
  thumbnail: IFile;
}

export type TUpdateMediaParameter = {
  id: number;
  payload: Partial<ICreateMedia>;
};

export type TQueryMedia = IRquestParams<{
  title?: string;
  type?: EMediaType;
}>;
