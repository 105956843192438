import { Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useState } from "react";
import { ActionTable, AInput } from "~/components";
import { OPTION_FORMTYPE, OPTION_INTEREST } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useDeleteFormSubmit, useGetForms } from "~queries";
import { formatDate } from "~utils/dateHelper";
import ModalDetail from "./modal-detail";
import { IFormSubmit } from "~types";

const SubmitManagement = () => {
  const { confirmDelete, message } = useGlobal();
  const { mutate } = useDeleteFormSubmit();
  const [name, setName] = useState("");
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data, refetch } = useGetForms({
    ...pagination,
    filters: name ? { companyName: name } : {},
  });
  const [openModal, setOpenModal] = useState(false);
  const [submitInfo, setSubmitInfo] = useState<IFormSubmit>({} as IFormSubmit);
  const [isView, setIsView] = useState(false);

  const onSearch = (value: string) => {
    setName(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          refetch();
        },
        onError: () => {
          message.error("Delete failed");
        },
      });
    }
  };
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSubmitInfo({} as IFormSubmit);
  };

  const onEditUser = (submitInfo: IFormSubmit) => {
    setSubmitInfo(submitInfo);
    showModal();
    setIsView(true);
  };

  const columns: ColumnType<IFormSubmit>[] = [
    {
      title: "Company",
      dataIndex: "companyName",
      width: 250,
    },
    {
      title: "Type",
      dataIndex: "formType",
      width: 150,
      render: (value) => OPTION_FORMTYPE[value].label,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 250,
    },
    {
      title: "Inquiry",
      dataIndex: "inquiry",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Interest",
      dataIndex: "interestContent",
      ellipsis: true,
      width: 250,
      render: (value) => OPTION_INTEREST[value].label,
    },
    {
      title: "Submit Date",
      dataIndex: "createdAt",
      width: 150,
      render: (value) => formatDate(value),
    },
    {
      title: "Actions",
      fixed: "right",
      width: 150,
      render: (_, record) => (
        <ActionTable onView={() => onEditUser(record)} onDelete={() => onDelete(record.id)} />
      ),
    },
  ];

  return (
    <>
      <AInput span={6} placeholder="Search" type="search" onSearch={onSearch} />
      <ModalDetail
        open={openModal}
        onCancel={closeModal}
        submitInfo={submitInfo}
        refetch={refetch}
        isView={isView}
      />
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 1200 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default SubmitManagement;
