import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { PATHS } from "~constants";
import { getAccessToken } from "~utils/funcHelper";

interface AuthLayoutProps {
  children: ReactNode;
  isPublic?: boolean;
}

const AuthLayout = ({ children, isPublic }: AuthLayoutProps) => {
  const accessToken = getAccessToken();

  if (accessToken && isPublic) {
    return <Navigate to={PATHS.WELCOME} replace />;
  }

  if (!accessToken && !isPublic) {
    return <Navigate to={PATHS.LOGIN} replace />;
  }

  return <div>{children}</div>;
};

export default AuthLayout;
