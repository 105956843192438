import { useQueryClient } from "@tanstack/react-query";
import { Form, Modal, ModalProps, UploadFile } from "antd";
import { useEffect, useState } from "react";
import { AInput, ASelect, AUpload } from "~/components";
import { MEDIA_TYPE } from "~constants";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreateMedia, useUpdateMedia } from "~queries";
import { IMedia } from "~types";

interface IMediaModalProps extends ModalProps {
  media: IMedia;
  onCancel: () => void;
}

const MediaModal = ({ media, onCancel, ...props }: IMediaModalProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreateMedia();
  const { mutate: mutateUpdate } = useUpdateMedia();
  const { fileId, setFileId, fileList, setFileList, handleUploadChange, loading, deleteFile } =
    useUploadFile();
  const {
    fileId: thumbnailId,
    setFileId: setThumbnailId,
    fileList: thumbnailData,
    setFileList: setThumbnailData,
    handleUploadChange: handleUploadThumbnail,
    loading: thumbnailLoading,
    deleteFile: deleteThumbnailFile,
  } = useUploadFile();
  const [deletedIds, setDeletedIds] = useState<number[]>([]);
  const isEdit = Object.keys(media).length > 0;
  const action = `${isEdit ? "Update" : "Create New"} Media`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
    if (deletedIds.includes(fileId as number)) {
      deleteFile();
    }
    if (deletedIds.includes(thumbnailId as number)) {
      deleteThumbnailFile();
    }
    queryClient.invalidateQueries({ queryKey: ["get-media-list"] });
    onCancel();
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    form.validateFields().then((payload) => {
      payload.fileId = fileId;
      payload.thumbnailId = thumbnailId;
      if (isEdit) {
        mutateUpdate({ id: media.id, payload }, { onSuccess, onError });
      } else {
        mutateCreate(payload, { onSuccess, onError });
      }
    });
  };

  const setValue = () => {
    if (isEdit) {
      form.setFieldsValue(media);
      if (media?.file) {
        const defaultFileId = media.file?.id;
        const defaultFile = { uid: String(defaultFileId), name: "Document.pdf" };
        setFileList([defaultFile]);
        setFileId(defaultFileId);
      }

      if (media?.thumbnail) {
        const defaultThumbnailId = media.thumbnail?.id;
        const defaultThumbnail = { uid: String(defaultThumbnailId), name: "Thumbnail.jpg" };
        setThumbnailData([defaultThumbnail]);
        setThumbnailId(defaultThumbnailId);
      }
    } else {
      setFileList([]);
      setThumbnailData([]);
      form.resetFields();
    }
  };

  const handleRemoveFile = (file: UploadFile) => {
    if (!file?.originFileObj) {
      setDeletedIds((prev) => [...prev, Number(file.uid)]);
    }
  };

  useEffect(() => {
    setValue();
  }, [form, isEdit, media]);

  return (
    <Modal title={action} onOk={onSubmit} onCancel={onCancel} width={800} centered {...props}>
      <Form layout="vertical" form={form}>
        <AInput
          type="textarea"
          placeholder="Enter title"
          formItemProps={{ name: "title", label: "Title", rules: [{ required: true }] }}
          textAreaProps={{ rows: 3 }}
        />

        <AInput
          type="textarea"
          placeholder="Enter content"
          formItemProps={{ name: "content", label: "Content", rules: [{ required: true }] }}
          textAreaProps={{ rows: 5 }}
        />

        <ASelect
          placeholder="Select a media type"
          options={MEDIA_TYPE}
          allowClear={false}
          formItemProps={{
            name: "type",
            label: "Media Type",
            rules: [{ required: true, message: "Please select media type" }],
          }}
        />

        <AUpload
          formItemProps={{
            name: "file",
            label: "Document",
            // rules: [{ required: true, message: "Please upload document" }],
          }}
          accept=".pdf"
          errorMsg="is not a PDF file"
          allowedTypes={["application/pdf"]}
          fileList={fileList}
          loading={loading}
          onChange={({ file, fileList }) => handleUploadChange(file, fileList, isEdit)}
          onRemove={handleRemoveFile}
        />

        <AUpload
          formItemProps={{
            name: "thumbnail",
            label: "Thumbnail",
            // rules: [{ required: true, message: "Please upload thumbnail" }],
          }}
          loading={thumbnailLoading}
          fileList={thumbnailData}
          onChange={({ file, fileList }) => handleUploadThumbnail(file, fileList, isEdit)}
          onRemove={handleRemoveFile}
        />
      </Form>
    </Modal>
  );
};

export default MediaModal;
