import request from "~libs/axios";
import {
  ICreateCustomer,
  IResponse,
  TCustomer,
  TQueryCustomer,
  TUpdateCustomerParameter,
} from "~types";

const END_POINT = "/customers/";

const customerService = {
  getAll(params: TQueryCustomer): Promise<IResponse<TCustomer>> {
    return request.get(END_POINT, { params });
  },
  get(id: number) {
    return request.get(END_POINT + id);
  },
  create(payload: ICreateCustomer) {
    return request.post(END_POINT, payload);
  },
  update({ id, payload }: TUpdateCustomerParameter) {
    return request.patch(END_POINT + id, payload);
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default customerService;
