import { Button, ButtonProps } from "antd";
import clsx from "clsx";
import "./a-button.scss";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";

interface IAButtonProps extends ButtonProps {
  iconType?: "add" | "edit" | "delete" | "upload" | "view";
}

const icons = {
  edit: <EditOutlined />,
  add: <PlusCircleOutlined />,
  delete: <DeleteOutlined />,
  upload: <UploadOutlined />,
  view: <EyeOutlined />,
};

const AButton = ({ children, className, type = "primary", iconType, ...props }: IAButtonProps) => {
  const btnClassName = clsx("a-button", className);

  return (
    <Button className={btnClassName} icon={iconType && icons[iconType]} type={type} {...props}>
      {children}
    </Button>
  );
};

export default AButton;
