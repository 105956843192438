import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { AButton } from "~/components";
import { PATHS } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useLogIn } from "~queries";
import { ICredentials } from "~types";
import { setCredentials } from "~utils/funcHelper";
import "./login.scss";

const LoginPage = () => {
  const navigate = useNavigate();
  const { message } = useGlobal();
  const { mutate, isPending } = useLogIn();

  const onFinish = (credentials: ICredentials) => {
    mutate(credentials, {
      onSuccess(data) {
        message.success("Login successfully");
        setCredentials(data.token, data.refreshToken);
        navigate(PATHS.WELCOME);
      },
      onError(error: any) {
        if (error.status === 500) {
          message.error("Internal server error");
        } else {
          message.error("Email or password is incorrect");
        }
      },
    });
  };

  return (
    <div className="login-page h-screen dis-flex ai-center jc-center">
      <img src="./logo.png" alt="ICapital Asia" width={130} className="pos-absolute z-100 top-80" />
      <Card className="w-450" title="Log in Icapital Asia CMS">
        <Form onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, type: "email" }]}
            normalize={(value) => value.trim()}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item name="password" className="my-40" rules={[{ required: true }]}>
            <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <AButton type="primary" htmlType="submit" block loading={isPending}>
              Log In
            </AButton>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPage;
