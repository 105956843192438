import { Flex, Form, Row, Table, Tag } from "antd";
import { ColumnType } from "antd/es/table";
import { useState } from "react";
import { AButton, AInput, ASelect } from "~/components";
import ActionTable from "~/components/action-table";
import { NEWS_TYPE, NEWS_TYPE_COLOR } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useDeleteNews, useGetNews } from "~queries";
import { ENewsType, INews } from "~types";
import { formatDate } from "~utils/dateHelper";
import { getImgUrl, getLabelByValue } from "~utils/funcHelper";
import NewsModal from "./info-modal";

const NewsList = () => {
  const { confirmDelete, message } = useGlobal();
  const { mutate } = useDeleteNews();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data, refetch } = useGetNews({ ...pagination, filters });
  const [openModal, setOpenModal] = useState(false);
  const [news, setNews] = useState<INews>({} as INews);

  const onSearch = (key: string, value: string | number) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete news successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          refetch();
        },
        onError: () => {
          message.error("Delete news failed");
        },
      });
    }
  };
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setNews({} as INews);
  };

  const onEditNews = (news: INews) => {
    setNews(news);
    showModal();
  };

  const onView = (news: INews) => {
    window.open(getImgUrl(news?.file?.fileName), "_blank");
  };

  const columns: ColumnType<INews>[] = [
    {
      title: "Title",
      dataIndex: "title",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (value: ENewsType) => (
        <Tag color={NEWS_TYPE_COLOR[value]}>{getLabelByValue(NEWS_TYPE, value)}</Tag>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (value) => formatDate(value),
    },
    {
      title: "Actions",
      width: 180,
      fixed: "right",
      render: (_, record) => (
        <ActionTable
          onView={() => onView(record)}
          onEdit={() => onEditNews(record)}
          onDelete={() => onDelete(record.id)}
        />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} justify="space-between" align="center">
        <Form layout="vertical" className="w--90">
          <Row gutter={[10, 10]}>
            <ASelect
              span={5}
              options={NEWS_TYPE}
              placeholder="Select news type"
              formItemProps={{ label: "News Type" }}
              onChange={(value) => onSearch("type", value)}
            />
            <AInput
              span={8}
              placeholder="Search news by title"
              formItemProps={{ label: "Title" }}
              type="search"
              onSearch={(value) => onSearch("title", value)}
            />
          </Row>
        </Form>
        <AButton iconType="add" onClick={showModal}>
          Create
        </AButton>
      </Flex>
      <NewsModal open={openModal} onCancel={closeModal} news={news} />
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default NewsList;
