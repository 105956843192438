import { useMutation, useQuery } from "@tanstack/react-query";
import mediaService from "~services/mediaService";
import { ICreateMedia, TQueryMedia, TUpdateMediaParameter } from "~types";

export const useGetMedia = (params: TQueryMedia) =>
  useQuery({
    queryKey: ["get-media-list", params],
    queryFn: () => mediaService.getAll(params),
  });

export const useCreateMedia = () =>
  useMutation({
    mutationKey: ["create-media"],
    mutationFn: (payload: ICreateMedia) => mediaService.create(payload),
  });

export const useUpdateMedia = () =>
  useMutation({
    mutationKey: ["update-media"],
    mutationFn: ({ id, payload }: TUpdateMediaParameter) => mediaService.update({ id, payload }),
  });

export const useDeleteMedia = () =>
  useMutation({
    mutationKey: ["delete-media"],
    mutationFn: (id: number) => mediaService.delete(id),
  });
