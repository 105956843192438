import { useQueryClient } from "@tanstack/react-query";
import { Col, Flex, Form, Image, Modal, ModalProps, Row } from "antd";
import { useEffect, useState } from "react";
import { AInput, AUpload } from "~/components";
import TextEditor from "~/components/text-editor";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreateMember, useUpdateMember } from "~queries";
import { IMember } from "~types";

interface IMemberModalProps extends ModalProps {
  member: IMember;
  onCancel: () => void;
  isView?: boolean;
}

const MemberModal = ({ member, onCancel, isView, ...props }: IMemberModalProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);
  const { mutate: mutateCreate } = useCreateMember();
  const { mutate: mutateUpdate } = useUpdateMember();
  const { fileId, setFileId, fileList, setFileList, handleUploadChange, loading } = useUploadFile();
  const isEdit = Object.keys(member).length > 0;
  const action = `${isEdit ? "Update" : "Create New"} Member`;

  const handleCancelModal = () => {
    setContentError(false);
    setContent("");
    onCancel();
  };

  const onSuccess = () => {
    message.success(`${action} successfully`);
    queryClient.invalidateQueries({ queryKey: ["get-team"] });
    handleCancelModal();
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    if (!content) setContentError(true);
    form.validateFields().then((payload) => {
      if (!content) return;
      payload.avatarId = fileId;
      payload.description = content;
      if (isEdit) {
        mutateUpdate({ id: member.id, payload }, { onSuccess, onError });
      } else {
        mutateCreate(payload, { onSuccess, onError });
      }
    });
  };

  const setValue = () => {
    if (isEdit) {
      form.setFieldsValue(member);
      const defaultFile = {
        uid: String(member?.avatar?.id),
        name: member?.fullName + "-profile-picture.jpg",
      };
      setFileList([defaultFile]);
      setFileId(member?.avatar?.id);
      setContent(member?.description);
    } else {
      setFileList([]);
      form.resetFields();
    }
  };

  useEffect(() => {
    setValue();
  }, [form, isEdit, member]);

  const onEditorChange = (value: string) => {
    if (value) {
      setContentError(false);
    } else {
      setContentError(true);
    }
    setContent(value);
  };

  return (
    <>
      {isView ? (
        <Modal
          title={`${member?.fullName}'s Details`}
          cancelText="Close"
          className="detail-modal"
          okButtonProps={{ className: "dis-none" }}
          width={1000}
          centered
          onCancel={onCancel}
          {...props}
        >
          <Flex gap={30}>
            <Image width={150} src={member?.avatarUrl} alt="avatar" />
            <div>
              <div className="detail__row">
                <div className="detail__label">Full Name</div>
                <div>{member.fullName}</div>
              </div>

              <div className="detail__row">
                <div className="detail__label">Role</div>
                <span>{member.position}</span>
              </div>
            </div>
          </Flex>
          <br />
          <span className="ql-editor" dangerouslySetInnerHTML={{ __html: member.description }} />
        </Modal>
      ) : (
        <Modal
          title={action}
          onOk={onSubmit}
          width={850}
          centered
          onCancel={handleCancelModal}
          {...props}
        >
          <Form layout="vertical" form={form}>
            <Row gutter={[20, 10]}>
              <AInput
                span={12}
                placeholder="Enter full name"
                formItemProps={{
                  name: "fullName",
                  label: "Full Name",
                  rules: [{ required: true, max: 100 }],
                }}
              />

              <AInput
                span={12}
                placeholder="Enter role"
                formItemProps={{
                  name: "position",
                  label: "Role",
                  rules: [{ required: true, max: 100 }],
                }}
              />

              <AInput
                span={12}
                type="number"
                placeholder="Enter display order"
                formItemProps={{ name: "displayOrder", label: "Display Order" }}
                inputNumberProps={{ min: 1 }}
              />

              <AUpload
                loading={loading}
                span={12}
                formItemProps={{
                  name: "avatar",
                  label: "Profile Picture",
                  rules: [{ required: true, message: "Please upload profile picture" }],
                }}
                fileList={fileList}
                onChange={({ file, fileList }) => handleUploadChange(file, fileList, isEdit)}
              />

              <Col span={24}>
                <Form.Item label="Description" required>
                  <TextEditor
                    value={content}
                    onChange={onEditorChange}
                    isError={contentError}
                    label="description"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default MemberModal;
