import { TabsProps } from "antd";
import { ATabs } from "~/components";
import ImpactGeneral from "./impact-general";
import ImpactList from "./impact-list";

const ImpactManagement = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General",
      children: <ImpactGeneral />,
    },
    {
      key: "2",
      label: "Impact Content",
      children: <ImpactList />,
    },
  ];

  return <ATabs items={items} />;
};

export default ImpactManagement;
