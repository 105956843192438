import { useMutation, useQuery } from "@tanstack/react-query";
import customerService from "~services/customerService";
import { ICreateCustomer, TQueryCustomer, TUpdateCustomerParameter } from "~types";

export const useGetCustomers = (params: TQueryCustomer) =>
  useQuery({
    queryKey: ["get-customers", params],
    queryFn: () => customerService.getAll(params),
  });

export const useCreateCustomer = () =>
  useMutation({
    mutationKey: ["create-customer"],
    mutationFn: (payload: ICreateCustomer) => customerService.create(payload),
  });

export const useUpdateCustomer = () =>
  useMutation({
    mutationKey: ["update-customer"],
    mutationFn: ({ id, payload }: TUpdateCustomerParameter) =>
      customerService.update({ id, payload }),
  });

export const useDeleteCustomer = () =>
  useMutation({
    mutationKey: ["delete-customer"],
    mutationFn: (id: number) => customerService.delete(id),
  });
