import { createBrowserRouter } from "react-router-dom";
import AuthLayout from "~/layouts/auth-layout";
import MainLayout from "~/layouts/main-layout";
import { PATHS } from "~constants";
import ContactUs from "~pages/contact-us";
import CustomerManagement from "~pages/customer";
import Home from "~pages/home";
import ImpactManagement from "~pages/impact";
import ImpactForm from "~pages/impact/impact-form";
import InfoManagement from "~pages/ir-information";
import JoinUs from "~pages/join-us";
import JoinUsForm from "~pages/join-us/join-us-form";
import LoginPage from "~pages/login";
import NotFound from "~pages/not-found";
import OurBusiness from "~pages/our-business";
import BusinessCategoryForm from "~pages/our-business/business-category-form";
import DealManagement from "~pages/our-deals";
import DealForm from "~pages/our-deals/deal-form";
import OurTeam from "~pages/our-team";
import SubmitManagement from "~pages/submit";
import Welcome from "~pages/welcome";
import WhoWeAre from "~pages/who-we-are";

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <AuthLayout>
        <MainLayout />
      </AuthLayout>
    ),
    children: [
      {
        index: true,
        element: <Welcome />,
      },
      {
        path: PATHS.HOME,
        element: <Home />,
      },
      {
        path: PATHS.TEAM,
        element: <OurTeam />,
      },
      {
        path: PATHS.IMPACT,
        element: <ImpactManagement />,
      },
      {
        path: PATHS.CREATE_IMPACT,
        element: <ImpactForm />,
      },
      {
        path: PATHS.IMPACT_DETAIL,
        element: <ImpactForm />,
      },
      {
        path: PATHS.WHO_WE_ARE,
        element: <WhoWeAre />,
      },
      {
        path: PATHS.DEALS,
        element: <DealManagement />,
      },
      {
        path: PATHS.CREATE_DEAL,
        element: <DealForm />,
      },
      {
        path: PATHS.DEAL_DETAIL,
        element: <DealForm />,
      },
      {
        path: PATHS.CONTACT_US,
        element: <ContactUs />,
      },
      {
        path: PATHS.JOIN_US,
        element: <JoinUs />,
      },
      {
        path: PATHS.CREATE_JOINUS,
        element: <JoinUsForm />,
      },
      {
        path: PATHS.JOINUS_DETAIL,
        element: <JoinUsForm />,
      },
      {
        path: PATHS.OUR_BUSINESS,
        element: <OurBusiness />,
      },
      {
        path: PATHS.BUSINESS_CATEGORY,
        element: <BusinessCategoryForm />,
      },
      {
        path: PATHS.BUSINESS_CATEGORY_DETAIL,
        element: <BusinessCategoryForm />,
      },
      {
        path: PATHS.IR_INFORMATION,
        element: <InfoManagement />,
      },
      {
        path: PATHS.CUSTOMER,
        element: <CustomerManagement />,
      },
      {
        path: PATHS.SUBMIT,
        element: <SubmitManagement />,
      },
    ],
  },
  {
    path: PATHS.LOGIN,
    element: (
      <AuthLayout isPublic>
        <LoginPage />
      </AuthLayout>
    ),
  },
  {
    path: PATHS.ALL,
    element: <NotFound />,
  },
]);

export default router;
