import { Suspense } from "react";
import "react-quill/dist/quill.core.css";
import { RouterProvider } from "react-router-dom";
import { GlobalProvider } from "~hooks/useGlobal";
import AntdProvider from "~libs/antd/AntdProvider";
import ReactQueryProvider from "~libs/react-query/ReactQueryProvider";
import router from "~routers";
import { Loading } from "./components";

function App() {
  return (
    <Suspense fallback={<Loading show />}>
      <AntdProvider>
        <ReactQueryProvider>
          <GlobalProvider>
            <RouterProvider router={router} />
          </GlobalProvider>
        </ReactQueryProvider>
      </AntdProvider>
    </Suspense>
  );
}

export default App;
