import { Flex, Table } from "antd";
import { useState } from "react";
import { AButton, ActionTable, AInput } from "~/components";
import useGlobal from "~hooks/useGlobal";
import { useDeleteCustomer, useGetCustomers } from "~queries";
import { TCustomer } from "~types";
import CustomerModal from "./modal";
import { ColumnType } from "antd/es/table";

const CustomerManagement = () => {
  const { confirmDelete, message } = useGlobal();
  const { mutate } = useDeleteCustomer();
  const [name, setName] = useState("");
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data, refetch } = useGetCustomers({ ...pagination, filters: { name } });
  const [openModal, setOpenModal] = useState(false);
  const [customer, setCustomer] = useState<TCustomer>({} as TCustomer);

  const onSearch = (value: string) => {
    setName(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete Customer successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          refetch();
        },
        onError: () => {
          message.error("Delete Customer failed");
        },
      });
    }
  };
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setCustomer({} as TCustomer);
  };

  const onEditCustomer = (customer: TCustomer) => {
    setCustomer(customer);
    showModal();
  };

  const columns: ColumnType<TCustomer>[] = [
    {
      title: "Company Name",
      dataIndex: "name",
    },
    {
      title: "Industry",
      dataIndex: "industry",
    },
    {
      title: "Sales",
      dataIndex: "sales",
    },
    {
      title: "Age of CEO",
      dataIndex: "ceoAge",
    },
    {
      title: "Actions",
      fixed: "right",
      width: 150,
      render: (_, record) => (
        <ActionTable onEdit={() => onEditCustomer(record)} onDelete={() => onDelete(record.id)} />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} justify="space-between">
        <AInput span={6} placeholder="Search company by name" type="search" onSearch={onSearch} />
        <AButton iconType="add" onClick={showModal}>
          Create
        </AButton>
      </Flex>
      <CustomerModal open={openModal} onCancel={closeModal} customer={customer} />
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default CustomerManagement;
