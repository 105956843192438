import { Flex, Form, Row, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AButton, ActionTable, AInput, ASelect } from "~/components";
import { JOB_INDUSTRY_TYPE, JOB_TYPE, PATHS } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useDeleteJob, useGetJobs } from "~queries";
import { getLabelByValue, replaceIdWithValue } from "~utils/funcHelper";

const JobList = () => {
  const navigate = useNavigate();
  const { confirmDelete, message } = useGlobal();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data, refetch } = useGetJobs({ ...pagination, filters });

  const { mutate } = useDeleteJob();

  const onSearch = (key: string, value: string | number) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onCreate = () => navigate(PATHS.CREATE_JOINUS);

  const onEdit = (id: number) => {
    const url = replaceIdWithValue(PATHS.JOINUS_DETAIL, id);
    navigate(url);
  };

  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete job successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          refetch();
        },
        onError: () => {
          message.error("Delete job failed");
        },
      });
    }
  };

  const columns: ColumnType<any>[] = [
    {
      title: "Title",
      dataIndex: "title",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "center",
      width: 160,
      render: (value) => getLabelByValue(JOB_TYPE, value),
    },
    {
      title: "Industry",
      dataIndex: "industryType",
      align: "center",
      width: 160,
      render: (value) => getLabelByValue(JOB_INDUSTRY_TYPE, value),
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Actions",
      fixed: "right",
      align: "center",
      width: 160,
      render: (_, record) => (
        <ActionTable onEdit={() => onEdit(record.id)} onDelete={() => onDelete(record.id)} />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} justify="space-between" align="center">
        <Form layout="vertical" className="w--90">
          <Row gutter={[10, 10]}>
            <ASelect
              span={5}
              options={JOB_TYPE}
              placeholder="Select a job type"
              formItemProps={{ label: "Job type" }}
              onChange={(value) => onSearch("type", value)}
            />
            <AInput
              span={8}
              placeholder="Search jobs by title"
              formItemProps={{ label: "Title" }}
              type="search"
              onSearch={(value) => onSearch("title", value)}
            />
          </Row>
        </Form>
        <AButton iconType="add" onClick={onCreate}>
          Create
        </AButton>
      </Flex>
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default JobList;
