import { Form, Row, Space } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AButton, AInput } from "~/components";
import ASelect from "~/components/common/a-select";
import { JOB_INDUSTRY_TYPE, JOB_TYPE, PATHS } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useCreateJob, useGetJob, useUpdateJob } from "~queries";
import { ICreateJob } from "~types";

const JoinUsForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const jobId = Number(id);
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreateJob();
  const { mutate: mutateUpdate } = useUpdateJob();
  const { data: jobDetail } = useGetJob(jobId, { enabled: !!jobId });
  const isEdit = jobDetail && Object.keys(jobDetail).length > 0;
  const action = `${id ? "Update" : "Create New"} Job`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
    backToList();
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async (payload: ICreateJob) => {
    if (isEdit) {
      mutateUpdate({ id: jobDetail.id, payload }, { onSuccess, onError });
    } else {
      mutateCreate(payload, { onSuccess, onError });
    }
  };

  const backToList = () => navigate({ pathname: PATHS.JOIN_US, search: `tab=2` });

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(jobDetail);
    }
  }, [isEdit, jobDetail]);

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <h2 className="mb-20">{action}</h2>
      <Row gutter={[20, 20]}>
        <AInput
          span={12}
          placeholder="Enter job title"
          formItemProps={{ name: "title", label: "Title", rules: [{ required: true }] }}
        />
        <ASelect
          span={6}
          placeholder="Select a type"
          options={JOB_TYPE}
          formItemProps={{
            name: "type",
            label: "Type",
            rules: [{ required: true }],
          }}
        />
        <ASelect
          span={6}
          placeholder="Select a industry"
          options={JOB_INDUSTRY_TYPE}
          formItemProps={{
            name: "industryType",
            label: "Industry",
            rules: [{ required: true }],
          }}
        />
        <AInput
          span={24}
          type="textarea"
          formItemProps={{ name: "description", label: "Description", rules: [{ required: true }] }}
          textAreaProps={{ rows: 5, placeholder: "Enter job description" }}
        />
      </Row>
      <Space className="mt-20">
        <AButton htmlType="submit">Save</AButton>
        <AButton type="default" onClick={backToList}>
          Cancel
        </AButton>
      </Space>
    </Form>
  );
};

export default JoinUsForm;
