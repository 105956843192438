import { useQueryClient } from "@tanstack/react-query";
import { Col, Form, Row } from "antd";
import { useEffect } from "react";
import { AButton, AInput, AUpload } from "~/components";
import { PAGE_ID } from "~constants";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreatePage, useGetPageByPageId, useUpdatePage } from "~queries";
import { getImgUrl } from "~utils/funcHelper";

const ContactUs = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreatePage(PAGE_ID.CONTACT_US);
  const { mutate: mutateUpdate } = useUpdatePage(PAGE_ID.CONTACT_US);
  const { data: pageContact } = useGetPageByPageId(PAGE_ID.CONTACT_US);
  const { fileId, handleUploadChange, fileList, setFileList, loading } = useUploadFile();
  const isEdit = pageContact?.id ? true : false;
  const action = `${isEdit ? "Update" : "Create"}`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
    queryClient.invalidateQueries({ queryKey: ["get-page"] });
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    form.validateFields().then((payload) => {
      const { banner, address, email, phone, ...rest } = payload;
      rest.bannerId = fileId ? fileId : pageContact?.bannerImg?.id;
      rest.pageId = PAGE_ID.CONTACT_US;
      rest.description = JSON.stringify({
        address,
        email,
        phone,
      });
      if (isEdit) {
        mutateUpdate({ id: pageContact?.id, payload: rest }, { onSuccess, onError });
      } else {
        mutateCreate(rest, { onSuccess, onError });
      }
    });
  };

  useEffect(() => {
    if (!pageContact) {
      setFileList([]);
      return;
    }
    const description = pageContact.description ? JSON.parse(pageContact.description) : {};
    setFileList([
      {
        uid: "-1",
        name: "",
        status: "done",
        url: getImgUrl(pageContact?.bannerImg?.fileName),
        thumbUrl: getImgUrl(pageContact?.bannerImg?.fileName),
      },
    ]);

    form.setFieldsValue({
      banner: pageContact?.bannerImg?.id,
      address: description.address,
      email: description.email,
      phone: description.phone,
    });
  }, [pageContact]);

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Row gutter={[20, 20]}>
        <AUpload
          span={8}
          loading={loading}
          formItemProps={{
            name: "banner",
            label: "Banner",
            rules: [{ required: true }],
          }}
          listType="picture"
          onChange={({ file, fileList }) => handleUploadChange(file, fileList)}
          fileList={fileList}
        />
        <Col span={12} />
        <AInput
          span={12}
          formItemProps={{
            name: "address",
            label: "Address",
            rules: [{ required: true }],
          }}
        />
        <AInput
          span={6}
          formItemProps={{
            name: "email",
            label: "Email",
            rules: [{ required: true, type: "email" }],
          }}
        />
        <AInput
          span={6}
          type="number"
          inputNumberProps={{
            maxLength: 12,
            controls: false,
          }}
          formItemProps={{
            name: "phone",
            label: "Phone Number",
            rules: [{ required: true, type: "number" }],
          }}
        />
      </Row>
      <AButton htmlType="submit">Save</AButton>
    </Form>
  );
};

export default ContactUs;
