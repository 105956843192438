import request from "~libs/axios";
import { IFormSubmit, IResponse, IUpdateForm, TQueryFormSubmit } from "~types";

const END_POINT = "/submissions/";

const formSubmitService = {
  getAll(params: TQueryFormSubmit): Promise<IResponse<IFormSubmit>> {
    return request.get(END_POINT, { params });
  },
  get(id: number): any {
    return request.get(END_POINT + id);
  },
  create(payload: IFormSubmit) {
    return request.post(END_POINT, payload);
  },
  update({ id, payload }: IUpdateForm) {
    return request.patch(END_POINT + id, payload);
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default formSubmitService;
