import type { ThemeConfig } from "antd";

const color = {
  blue100: "#d5e8ff",
  black: "#000000",
  white: "#ffffff",
  success: "#52c41a",
};

const theme: ThemeConfig = {
  token: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 16,
    colorText: color.black,
  },
  components: {
    Table: {
      headerBg: color.blue100,
      headerColor: color.black,
      lineWidth: 2,
      fontSize: 16,
    },
    Layout: {
      headerBg: color.white,
    },
    Menu: {
      itemBorderRadius: 0,
    },
    Dropdown: {
      paddingBlock: 10,
    },
    Switch: {
      colorPrimary: color.success,
      colorPrimaryHover: color.success,
    },
  },
};

export default theme;
