import { Result } from "antd";
import { Link } from "react-router-dom";
import { AButton } from "~/components";
import { PATHS } from "~constants";

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <AButton>
        <Link to={PATHS.WELCOME}>Back Home</Link>
      </AButton>
    }
  />
);

export default NotFound;
