import { Form } from "antd";
import { useEffect } from "react";
import { AButton, AUpload } from "~/components";
import { PAGE_ID } from "~constants";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreatePage, useGetPageByPageId, useUpdatePage } from "~queries";
import { getImgUrl } from "~utils/funcHelper";

const InfoGeneral = () => {
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreatePage(PAGE_ID.NEW);
  const { mutate: mutateUpdate } = useUpdatePage(PAGE_ID.NEW);
  const { data: pageNew } = useGetPageByPageId(PAGE_ID.NEW);
  const { fileId, handleUploadChange, fileList, setFileList, loading } = useUploadFile();
  const isEdit = pageNew?.id ? true : false;
  const action = `${isEdit ? "Update" : "Create"}`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    form.validateFields().then((payload) => {
      const { pageType, banner, ...rest } = payload;
      rest.bannerId = fileId ? fileId : pageNew?.bannerImg?.id;
      rest.pageId = PAGE_ID.NEW;
      if (isEdit) {
        mutateUpdate({ id: pageNew?.id, payload: rest }, { onSuccess, onError });
      } else {
        mutateCreate(rest, { onSuccess, onError });
      }
    });
  };

  useEffect(() => {
    if (!pageNew) {
      setFileList([]);
      return;
    }
    setFileList([
      {
        uid: "-1",
        name: "",
        status: "done",
        url: getImgUrl(pageNew?.bannerImg?.fileName),
        thumbUrl: getImgUrl(pageNew?.bannerImg?.fileName),
      },
    ]);

    form.setFieldsValue({ banner: pageNew?.bannerImg?.id });
  }, [pageNew]);

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <AUpload
        loading={loading}
        span={8}
        formItemProps={{
          name: "banner",
          label: "Banner",
          rules: [{ required: true }],
        }}
        listType="picture"
        onChange={({ file, fileList }) => handleUploadChange(file, fileList)}
        fileList={fileList}
      />
      <AButton htmlType="submit" className="mt-20">
        Save
      </AButton>
    </Form>
  );
};

export default InfoGeneral;
