import { FileTextOutlined, HomeOutlined, ReadOutlined, TeamOutlined } from "@ant-design/icons";
import { Layout, Menu, SiderProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "~constants";
import { MenuItem } from "~types";
import { getItem } from "~utils/funcHelper";

const SideBar = (props: SiderProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedKeys = "/" + pathname.substring(1).split("/")[0];

  const menuItems: MenuItem[] = [
    getItem("Home", "/", <HomeOutlined />),
    getItem("Pages", "", <ReadOutlined />, [
      getItem("Home", PATHS.HOME),
      getItem("Our Deals", PATHS.DEALS),
      getItem("ESG Impact", PATHS.IMPACT),
      getItem("IR Information", PATHS.IR_INFORMATION),
      getItem("Who We Are", PATHS.WHO_WE_ARE),
      getItem("Our Business ", PATHS.OUR_BUSINESS),
      getItem("Contact Us ", PATHS.CONTACT_US),
      getItem("Join Us ", PATHS.JOIN_US),
      getItem("Team Member", PATHS.TEAM),
    ]),
    getItem("Customer Management", PATHS.CUSTOMER, <TeamOutlined />),
    getItem("Submission Management", PATHS.SUBMIT, <FileTextOutlined />),
  ];

  const handleMenuClick = ({ key }: { key: string }) => {
    navigate(key);
  };

  return (
    <Layout.Sider
      width={280}
      theme="dark"
      className="pos-fixed h-screen shadow-mid-right z-20"
      {...props}
    >
      <img src="/logo.png" alt="ICapital Asia" width={100} className="margin-center" />
      <Menu
        selectedKeys={[selectedKeys]}
        mode="inline"
        defaultOpenKeys={[""]}
        items={menuItems}
        theme="dark"
        onClick={handleMenuClick}
      />
    </Layout.Sider>
  );
};

export default SideBar;
