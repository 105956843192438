import { useQueryClient } from "@tanstack/react-query";
import { Form, Modal, ModalProps } from "antd";
import { useEffect } from "react";
import { AInput, AUpload } from "~/components";
import { PAGE_ID } from "~constants";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreatePage, useUpdatePage } from "~queries";
import { IPage } from "~types";

interface IBusinessModalProps extends ModalProps {
  business: IPage;
  onCancel: () => void;
}

const BusinessModal = ({ business, onCancel, ...props }: IBusinessModalProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreatePage(PAGE_ID.BUSINESS);
  const { mutate: mutateUpdate } = useUpdatePage(PAGE_ID.BUSINESS);
  const { fileId, setFileId, fileList, setFileList, handleUploadChange, loading } = useUploadFile();
  const {
    fileId: thumbnailId,
    setFileId: setThumbnailId,
    fileList: thumbnailData,
    setFileList: setThumbnailData,
    handleUploadChange: handleUploadThumbnail,
    loading: thumbnailLoading,
  } = useUploadFile();
  const isEdit = Object.keys(business).length > 0;
  const action = `${isEdit ? "Update" : "Create New"} Business`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
    queryClient.invalidateQueries({ queryKey: ["get-page-list"] });
    onCancel();
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    form.validateFields().then((payload) => {
      payload.bannerId = fileId;
      payload.thumbnailId = thumbnailId;
      payload.parentId = PAGE_ID.BUSINESS;
      if (isEdit) {
        delete payload.pageId;
        mutateUpdate({ id: business.id, payload }, { onSuccess, onError });
      } else {
        mutateCreate(payload, { onSuccess, onError });
      }
    });
  };

  const setValue = () => {
    if (isEdit) {
      form.setFieldsValue(business);

      const defaultBannerId = business?.bannerImg?.id;
      const defaultBanner = { uid: String(defaultBannerId), name: "Banner.jpg" };
      setFileList([defaultBanner]);
      setFileId(defaultBannerId);

      const defaultThumbnailId = business?.thumbnailImg?.id;
      const defaultThumbnail = { uid: String(defaultThumbnailId), name: "Thumbnail.jpg" };
      setThumbnailData([defaultThumbnail]);
      setThumbnailId(defaultThumbnailId);
    } else {
      setFileList([]);
      setThumbnailData([]);
      form.resetFields();
    }
  };

  useEffect(() => {
    setValue();
  }, [form, isEdit, business]);

  return (
    <Modal title={action} onOk={onSubmit} onCancel={onCancel} {...props}>
      <Form layout="vertical" form={form}>
        <AInput
          placeholder="Enter business name"
          formItemProps={{
            name: "title",
            label: "Business Name",
            rules: [{ required: true, max: 100 }],
          }}
        />

        <AUpload
          loading={thumbnailLoading}
          formItemProps={{
            name: "thumbnailImg",
            label: "Thumbnail",
            rules: [{ required: true, message: "Please upload thumbnail" }],
          }}
          fileList={thumbnailData}
          onChange={({ file, fileList }) => handleUploadThumbnail(file, fileList, isEdit)}
        />

        <AUpload
          loading={loading}
          formItemProps={{
            name: "bannerImg",
            label: "Banner",
            rules: [{ required: true, message: "Please upload banner" }],
          }}
          fileList={fileList}
          onChange={({ file, fileList }) => handleUploadChange(file, fileList, isEdit)}
        />
      </Form>
    </Modal>
  );
};

export default BusinessModal;
