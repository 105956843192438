import { useMutation, useQuery } from "@tanstack/react-query";
import jobService from "~services/jobService";
import { ICreateJob, IJob, TQueryJob, TQueryOptions, TUpdateJobParameter } from "~types";

export const useGetJobs = (params: TQueryJob) =>
  useQuery({
    queryKey: ["get-jobs", params],
    queryFn: () => jobService.getAll(params),
  });

export const useGetJob = (id: number, options?: TQueryOptions<IJob>) =>
  useQuery({
    queryKey: ["get-job", id],
    queryFn: () => jobService.get(id),
    ...options,
  });

export const useCreateJob = () =>
  useMutation({
    mutationKey: ["create-job"],
    mutationFn: (payload: ICreateJob) => jobService.create(payload),
  });

export const useUpdateJob = () =>
  useMutation({
    mutationKey: ["update-job"],
    mutationFn: ({ id, payload }: TUpdateJobParameter) => jobService.update({ id, payload }),
  });

export const useDeleteJob = () =>
  useMutation({
    mutationKey: ["delete-job"],
    mutationFn: (id: number) => jobService.delete(id),
  });
