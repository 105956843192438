import axios from "axios";

import requestHandler from "./requestHandler";
import responseHandler from "./responseHandler";
import { BASE_URL } from "~constants";
import { objectToQueryString } from "~utils/funcHelper";

const request = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
  paramsSerializer: (params) => objectToQueryString(params),
});

request.interceptors.request.use(requestHandler.success, requestHandler.error);

request.interceptors.response.use(responseHandler.success, responseHandler.error);

export default request;
