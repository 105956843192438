import { useMutation, useQuery } from "@tanstack/react-query";
import formSubmitService from "~services/formSubmit";
import { IFormSubmit, IUpdateForm, TQueryFormSubmit } from "~types";

export const useGetForms = (params: TQueryFormSubmit) =>
  useQuery({
    queryKey: ["get-form-submit", params],
    queryFn: () => formSubmitService.getAll(params),
  });

export const useCreateFormSubmit = () =>
  useMutation({
    mutationKey: ["create-form-submit"],
    mutationFn: (payload: IFormSubmit) => formSubmitService.create(payload),
  });

export const useUpdateFormSubmit = () =>
  useMutation({
    mutationKey: ["update-form-submit"],
    mutationFn: ({ id, payload }: IUpdateForm) => formSubmitService.update({ id, payload }),
  });

export const useDeleteFormSubmit = () =>
  useMutation({
    mutationKey: ["delete-form-submit"],
    mutationFn: (id: number) => formSubmitService.delete(id),
  });
