import { useQueryClient } from "@tanstack/react-query";
import { Form, Modal, ModalProps } from "antd";
import { useEffect } from "react";
import { AInput, ASelect, AUpload } from "~/components";
import { NEWS_TYPE } from "~constants";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreateNews, useUpdateNews } from "~queries";
import { INews } from "~types";

interface INewsModalProps extends ModalProps {
  news: INews;
  onCancel: () => void;
}

const NewsModal = ({ news, onCancel, ...props }: INewsModalProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreateNews();
  const { mutate: mutateUpdate } = useUpdateNews();
  const { fileId, setFileId, fileList, setFileList, handleUploadChange, loading } = useUploadFile();
  const isEdit = Object.keys(news).length > 0;
  const action = `${isEdit ? "Edit" : "Create New"} News`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
    queryClient.invalidateQueries({ queryKey: ["get-news"] });
    onCancel();
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    form.validateFields().then((payload) => {
      payload.fileId = fileId;
      if (isEdit) {
        mutateUpdate({ id: news.id, payload }, { onSuccess, onError });
      } else {
        mutateCreate(payload, { onSuccess, onError });
      }
    });
  };

  const setValue = () => {
    if (isEdit) {
      form.setFieldsValue(news);
      const defaultFile = {
        uid: String(news?.file?.id),
        name: news?.file.fileName,
      };
      setFileList([defaultFile]);
      setFileId(news?.file?.id);
    } else {
      setFileList([]);
      form.resetFields();
    }
  };

  useEffect(() => {
    setValue();
  }, [form, isEdit, news]);

  return (
    <Modal title={action} onOk={onSubmit} onCancel={onCancel} width={600} {...props}>
      <Form layout="vertical" form={form}>
        <AInput
          type="textarea"
          placeholder="Enter title"
          formItemProps={{ name: "title", label: "Title", rules: [{ required: true, max: 100 }] }}
          textAreaProps={{ rows: 3 }}
        />

        <ASelect
          placeholder="Select a news type"
          options={NEWS_TYPE}
          allowClear={false}
          formItemProps={{
            name: "type",
            label: "News Type",
            rules: [{ required: true, message: "Please select news type" }],
          }}
        />

        <AUpload
          formItemProps={{
            name: "file",
            label: "Document",
            rules: [{ required: true, message: "Please upload document" }],
          }}
          accept=".pdf"
          errorMsg="is not a PDF file"
          allowedTypes={["application/pdf"]}
          fileList={fileList}
          loading={loading}
          onChange={({ file, fileList }) => handleUploadChange(file, fileList, isEdit)}
        />
      </Form>
    </Modal>
  );
};

export default NewsModal;
