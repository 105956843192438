import { Form, Row } from "antd";
import { useEffect } from "react";
import { AButton, AInput, AUpload } from "~/components";
import { PAGE_ID } from "~constants";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreatePage, useGetPageByPageId, useUpdatePage } from "~queries";
import { getImgUrl } from "~utils/funcHelper";

const BusinessGeneral = () => {
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreatePage(PAGE_ID.BUSINESS);
  const { mutate: mutateUpdate } = useUpdatePage(PAGE_ID.BUSINESS);
  const { data: pageBusiness } = useGetPageByPageId(PAGE_ID.BUSINESS);
  const { fileId, handleUploadChange, fileList, setFileList, loading } = useUploadFile();
  const isEdit = pageBusiness?.id ? true : false;
  const action = `${isEdit ? "Update" : "Create"}`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    form.validateFields().then((payload) => {
      const { banner, ...rest } = payload;
      rest.bannerId = fileId ? fileId : pageBusiness?.bannerImg?.id;
      rest.pageId = PAGE_ID.BUSINESS;
      if (isEdit) {
        mutateUpdate({ id: pageBusiness?.id, payload: rest }, { onSuccess, onError });
      } else {
        mutateCreate(rest, { onSuccess, onError });
      }
    });
  };

  useEffect(() => {
    if (!pageBusiness) {
      setFileList([]);
      return;
    }
    setFileList([
      {
        uid: "-1",
        name: "",
        status: "done",
        url: getImgUrl(pageBusiness?.bannerImg?.fileName),
        thumbUrl: getImgUrl(pageBusiness?.bannerImg?.fileName),
      },
    ]);

    form.setFieldsValue({
      subTitle: pageBusiness?.subTitle,
      banner: pageBusiness?.bannerImg?.id,
    });
  }, [pageBusiness]);

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Row gutter={[20, 10]}>
        <AUpload
          loading={loading}
          span={8}
          formItemProps={{
            name: "banner",
            label: "Banner",
            rules: [{ required: true }],
          }}
          listType="picture"
          onChange={({ file, fileList }) => handleUploadChange(file, fileList)}
          fileList={fileList}
        />
        <AInput
          span={24}
          type="textarea"
          formItemProps={{
            name: "subTitle",
            label: "Description",
            rules: [{ required: true }],
          }}
          textAreaProps={{ rows: 6, placeholder: "Enter description" }}
        />
      </Row>
      <AButton htmlType="submit">Save</AButton>
    </Form>
  );
};

export default BusinessGeneral;
