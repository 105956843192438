import clsx from "clsx";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./text-editor.scss";

interface ITextEditorProps extends ReactQuillProps {
  className?: string;
  isError?: boolean;
  label?: string;
}

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "color",
  "align",
  "background",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const TextEditor = ({ className, isError, label, ...props }: ITextEditorProps) => {
  const editorClassName = clsx(
    "text-editor",
    {
      error: isError,
    },
    className,
  );

  return (
    <>
      <ReactQuill
        className={editorClassName}
        theme="snow"
        modules={modules}
        formats={formats}
        {...props}
      />
      {isError && <span className="wrong">Please enter {label}</span>}
    </>
  );
};

export default TextEditor;
