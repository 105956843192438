import { Tag, TagProps } from "antd";

interface IDealStatusProps extends TagProps {
  status: number | boolean;
}

const DealStatus = ({ status }: IDealStatusProps) => {
  return <Tag color={status ? "success" : "processing"}>{status ? "Successful" : "Live"}</Tag>;
};

export default DealStatus;
