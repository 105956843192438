import request from "~libs/axios";
import { ICreateMedia, IMedia, IResponse, TQueryMedia, TUpdateMediaParameter } from "~types";

const END_POINT = "/media/";

const mediaService = {
  getAll(params: TQueryMedia): Promise<IResponse<IMedia>> {
    return request.get(END_POINT, { params });
  },
  get(id: number) {
    return request.get(END_POINT + id);
  },
  create(payload: ICreateMedia) {
    return request.post(END_POINT, payload);
  },
  update({ id, payload }: TUpdateMediaParameter) {
    return request.patch(END_POINT + id, payload);
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default mediaService;
