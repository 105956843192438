import { useQueryClient } from "@tanstack/react-query";
import { Form, Modal, ModalProps } from "antd";
import { useEffect } from "react";
import { AInput } from "~/components";
import useGlobal from "~hooks/useGlobal";
import { useCreateCustomer, useUpdateCustomer } from "~queries";
import { TCustomer } from "~types";

interface ICustomerModalProps extends ModalProps {
  customer: TCustomer;
  onCancel: () => void;
}

const CustomerModal = ({ customer, onCancel, ...props }: ICustomerModalProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreateCustomer();
  const { mutate: mutateUpdate } = useUpdateCustomer();
  const isEdit = Object.keys(customer).length > 0;
  const action = `${isEdit ? "Update" : "Create New"} Customer`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
    queryClient.invalidateQueries({ queryKey: ["get-customers"] });
    onCancel();
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    form.validateFields().then((payload) => {
      payload.ceoAge = parseInt(payload.ceoAge);
      if (isEdit) {
        mutateUpdate({ id: customer.id, payload }, { onSuccess, onError });
      } else {
        mutateCreate(payload, { onSuccess, onError });
      }
    });
  };

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(customer);
    } else {
      form.resetFields();
    }
  }, [form, isEdit, customer]);

  return (
    <Modal title={action} onOk={onSubmit} onCancel={onCancel} {...props}>
      <Form layout="vertical" form={form}>
        <AInput
          placeholder="Enter company name"
          formItemProps={{ name: "name", label: "Company Name", rules: [{ required: true }] }}
        />

        <AInput
          placeholder="Enter industry"
          formItemProps={{ name: "industry", label: "Industry", rules: [{ required: true }] }}
        />

        <AInput
          placeholder="Enter sales"
          formItemProps={{ name: "sales", label: "Sales", rules: [{ required: true }] }}
        />

        <AInput
          placeholder="Enter age of CEO"
          type="number"
          formItemProps={{
            name: "ceoAge",
            label: "Age of CEO",
            rules: [{ required: true, type: "number" }],
          }}
        />
      </Form>
    </Modal>
  );
};

export default CustomerModal;
