import { Form } from "antd";
import { useEffect, useState } from "react";
import { AButton, AInput, ASelect, AUpload } from "~/components";
import { ESG_IMPACT, PAGE_ID } from "~constants";
import useGlobal from "~hooks/useGlobal";
import useUploadFile from "~hooks/useUploadFile";
import { useCreatePage, useGetPageByPageId, useUpdatePage } from "~queries";
import { getImgUrl } from "~utils/funcHelper";

export const pageOptions = [{ label: "ESG Impact", value: PAGE_ID.IMPACT }, ...ESG_IMPACT];

const ImpactGeneral = () => {
  const [form] = Form.useForm();
  const pageType = Form.useWatch("pageType", form);
  const [currentPageId, setCurrentPageId] = useState(PAGE_ID.IMPACT);
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreatePage(currentPageId);
  const { mutate: mutateUpdate } = useUpdatePage(currentPageId);
  const { data: pageImpact } = useGetPageByPageId(currentPageId);
  const { fileId, handleUploadChange, fileList, setFileList, loading } = useUploadFile();
  const isEdit = pageImpact?.id ? true : false;
  const action = `${isEdit ? "Update" : "Create"}`;

  const onSuccess = () => {
    message.success(`${action} successfully`);
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    form.validateFields().then((payload) => {
      const { pageType, banner, ...rest } = payload;
      rest.bannerId = fileId ? fileId : pageImpact?.bannerImg?.id;
      rest.pageId = pageType;
      if (isEdit) {
        mutateUpdate({ id: pageImpact?.id, payload: rest }, { onSuccess, onError });
      } else {
        mutateCreate(rest, { onSuccess, onError });
      }
    });
  };

  useEffect(() => {
    form.setFieldValue("pageType", pageOptions[0].value);
  }, []);

  useEffect(() => {
    if (!pageImpact) {
      setFileList([]);
      return;
    }
    setFileList([
      {
        uid: "-1",
        name: "",
        status: "done",
        url: getImgUrl(pageImpact?.bannerImg?.fileName),
        thumbUrl: getImgUrl(pageImpact?.bannerImg?.fileName),
      },
    ]);

    form.setFieldsValue({
      subTitle: pageImpact?.subTitle,
      pageType: pageImpact?.pageId,
      banner: pageImpact?.bannerImg?.id,
    });
  }, [pageImpact, currentPageId]);

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <ASelect
        span={5}
        options={pageOptions}
        placeholder="Select page to edit"
        formItemProps={{
          name: "pageType",
          label: "Select page to edit",
          rules: [{ required: true }],
        }}
        defaultValue={pageOptions[0].value}
        onChange={(value) => {
          setCurrentPageId(value);
        }}
      />
      <AUpload
        span={8}
        formItemProps={{
          name: "banner",
          label: "Banner",
          rules: [{ required: true }],
        }}
        loading={loading}
        listType="picture"
        onChange={({ file, fileList }) => handleUploadChange(file, fileList)}
        fileList={fileList}
      />
      {pageType === PAGE_ID.IMPACT && (
        <AInput
          span={24}
          type="textarea"
          formItemProps={{ name: "subTitle", label: "Message", rules: [{ required: true }] }}
          textAreaProps={{ rows: 6, placeholder: "Enter message" }}
        />
      )}
      <AButton htmlType="submit" className="mt-20">
        Save
      </AButton>
    </Form>
  );
};

export default ImpactGeneral;
