import { TabsProps } from "antd";
import { ATabs } from "~/components";
import InfoGeneral from "./info-general";
import InfoList from "./info-list";
import MediaList from "./media-list";

const InfoManagement = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General",
      children: <InfoGeneral />,
    },
    {
      key: "2",
      label: "News List",
      children: <InfoList />,
    },
    {
      key: "3",
      label: "Media List",
      children: <MediaList />,
    },
  ];

  return <ATabs items={items} />;
};

export default InfoManagement;
