import { Flex, Form, Image, Row, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useState } from "react";
import { AButton, AInput, ASelect } from "~/components";
import ActionTable from "~/components/action-table";
import { MEDIA_TYPE } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useDeleteMedia, useGetMedia } from "~queries";
import { IMedia } from "~types";
import { formatDate } from "~utils/dateHelper";
import { getImgUrl, getLabelByValue } from "~utils/funcHelper";
import MediaModal from "./media-modal";

const MediaList = () => {
  const { confirmDelete, message } = useGlobal();
  const { mutate } = useDeleteMedia();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data, refetch } = useGetMedia({ ...pagination, filters });
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState<IMedia>({} as IMedia);

  const onSearch = (key: string, value: string | number) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete media successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          refetch();
        },
        onError: () => {
          message.error("Delete media failed");
        },
      });
    }
  };
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setMedia({} as IMedia);
  };

  const onEditNews = (news: IMedia) => {
    setMedia(news);
    showModal();
  };

  const onView = (news: IMedia) => {
    window.open(getImgUrl(news?.file?.fileName), "_blank");
  };

  const columns: ColumnType<IMedia>[] = [
    {
      title: "Title",
      dataIndex: "title",
      ellipsis: true,
      width: "50%",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      align: "center",
      width: 150,
      render: (value) =>
        value?.fileName ? (
          <Image
            src={getImgUrl(value?.fileName)}
            alt="thumbnail"
            height={60}
            className="fit-cover"
          />
        ) : (
          "No thumbnail"
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 200,
      render: (value) => getLabelByValue(MEDIA_TYPE, value),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: 150,
      align: "center",
      render: (value) => formatDate(value),
    },
    {
      title: "Actions",
      width: 200,
      fixed: "right",
      render: (_, record) => (
        <ActionTable
          onView={record?.file ? () => onView(record) : undefined}
          onEdit={() => onEditNews(record)}
          onDelete={() => onDelete(record.id)}
        />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} justify="space-between" align="center">
        <Form layout="vertical" className="w--90">
          <Row gutter={[10, 10]}>
            <ASelect
              span={5}
              options={MEDIA_TYPE}
              placeholder="Select media type"
              formItemProps={{ label: "Media Type" }}
              onChange={(value) => onSearch("type", value)}
            />
            <AInput
              span={8}
              placeholder="Search media by title"
              formItemProps={{ label: "Title" }}
              type="search"
              onSearch={(value) => onSearch("title", value)}
            />
          </Row>
        </Form>
        <AButton iconType="add" onClick={showModal}>
          Create
        </AButton>
      </Flex>
      <MediaModal open={openModal} onCancel={closeModal} media={media} />
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default MediaList;
