import { App } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { HookAPI } from "antd/es/modal/useModal";
import { createContext, ReactNode, useContext } from "react";

export interface IGlobalContext {
  modal: HookAPI;
  message: MessageInstance;
  confirmDelete: () => Promise<boolean>;
  showError: (error: Error, defaultMsg?: string) => void;
}

export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const { modal, message } = App.useApp();

  const confirmDelete = async () => {
    return modal.confirm({
      title: "Confirm",
      content: "Are you sure to delete this record?",
    });
  };

  const showError = (error: any, defaultMsg = "Something went wrong") => {
    if (error?.errors) {
      Object.values(error?.errors).forEach((value) => message.error(value as string));
      return;
    }

    message.error(error?.message ?? defaultMsg);
  };

  return (
    <GlobalContext.Provider value={{ modal, message, confirmDelete, showError }}>
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
};

export default useGlobal;
