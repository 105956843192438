import { TabsProps } from "antd";
import { ATabs } from "~/components";
import JoinUsGeneral from "./join-us-general";
import JobList from "./job-list";

const JoinUs = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General",
      children: <JoinUsGeneral />,
    },
    {
      key: "2",
      label: "Job List",
      children: <JobList />,
    },
  ];

  return <ATabs items={items} />;
};

export default JoinUs;
