import { CaretDownOutlined, FormOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, MenuProps } from "antd";
import { BasicProps } from "antd/es/layout/layout";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { PATHS } from "~constants";
import { logOut } from "~utils/funcHelper";

const Header = ({ className, ...props }: BasicProps) => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    navigate(PATHS.LOGIN);
    logOut();
  };

  const items: MenuProps["items"] = [
    // {
    //   key: "1",
    //   label: "Change password",
    //   icon: <FormOutlined />,
    // },
    {
      key: "2",
      label: "Log out",
      icon: <LogoutOutlined />,
      onClick: handleLogOut,
    },
  ];

  const headerClassName = clsx(
    "dis-flex ai-center px-15 jc-flex-end pos-fixed top-0 z-10 w-full border-b-1 border-gray-20",
    className,
  );

  return (
    <Layout.Header className={headerClassName} {...props}>
      <Dropdown menu={{ items }}>
        <div>
          <span className="fw-600 fs-16 mr-10">
            Hi, admin <CaretDownOutlined />
          </span>
          <Avatar src="/avatar.jpg" size="large" />
        </div>
      </Dropdown>
    </Layout.Header>
  );
};

export default Header;
