import { Key, ReactNode } from "react";
import { ACCESS_TOKEN, HOST, REFRESH_TOKEN, USER_INFO } from "~constants";
import { MenuItem } from "~types";
import { localCache } from "./cacheHelper";

export const getAccessToken = () => localCache.get(ACCESS_TOKEN);

export const setCredentials = (token: string, refreshToken: string) => {
  localCache.set(ACCESS_TOKEN, token);
  localCache.set(REFRESH_TOKEN, refreshToken);
};

export const logOut = () => {
  localCache.remove(ACCESS_TOKEN);
  localCache.remove(REFRESH_TOKEN);
  localCache.remove(USER_INFO);
};

export const isNull = <T>(value: T) => value === null || value === undefined || value === "";

export const replaceIdWithValue = (url: string, value: string | number) =>
  url.replace(`:id`, String(value));

export const getLabelByValue = <T extends Record<string, number | string>>(
  array: T[],
  value: number | string,
) => {
  return array.find((item) => item.value === value)?.label ?? "";
};

export function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[],
  isHide?: boolean,
) {
  return isHide ? null : { label, key, icon, children };
}

function arrayToQueryString(key: string, array: string[]) {
  return array.map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join("&");
}

export function objectToQueryString(params: Record<string, string>) {
  return Object.entries(params)
    .map(([key, value]) => {
      if (["filters", "sort"].includes(key)) {
        return `${key}=${JSON.stringify(value)}`;
      }
      if (Array.isArray(value)) {
        return arrayToQueryString(key, value);
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
}

export const getImgUrl = (fileName?: string) => `${HOST}/${fileName}`;

export const getOptions = <T extends Record<string, any>>(label: string, options?: T[]) => {
  if (!options) return [];
  return options.map((option) => ({
    label: option[label],
    value: option.id,
  }));
};
