import { TabsProps } from "antd";
import { ATabs } from "~/components";
import BusinessCategoryList from "./business-category-list";
import BusinessGeneral from "./business-general";
import BusinessList from "./business-list";

const OurBusiness = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General",
      children: <BusinessGeneral />,
    },
    {
      key: "2",
      label: "Business List",
      children: <BusinessList />,
    },
    {
      key: "3",
      label: "Business Category",
      children: <BusinessCategoryList />,
    },
  ];

  return <ATabs items={items} />;
};

export default OurBusiness;
