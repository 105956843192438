import request from "~libs/axios";
import { ICreateNews, IResponse, INews, TQueryNews, TUpdateNewsParameter } from "~types";

const END_POINT = "/news/";

const newsService = {
  getAll(params: TQueryNews): Promise<IResponse<INews>> {
    return request.get(END_POINT, { params });
  },
  get(id: number) {
    return request.get(END_POINT + id);
  },
  create(payload: ICreateNews) {
    return request.post(END_POINT, payload);
  },
  update({ id, payload }: TUpdateNewsParameter) {
    return request.patch(END_POINT + id, payload);
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default newsService;
