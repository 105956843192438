import { useMutation, useQuery } from "@tanstack/react-query";
import memberService from "~services/memberService";
import { ICreateMember, TQueryMember, TUpdateMemberParameter } from "~types";
import { formatDate } from "~utils/dateHelper";
import { getImgUrl } from "~utils/funcHelper";

export const useGetTeam = (params: TQueryMember) =>
  useQuery({
    queryKey: ["get-team", params],
    queryFn: () => memberService.getAll(params),
    select: (response) => ({
      ...response,
      data: response.data.map((item) => ({
        ...item,
        avatarUrl: getImgUrl(item.avatar?.fileName),
        createdAt: formatDate(item.createdAt),
      })),
    }),
  });

export const useCreateMember = () =>
  useMutation({
    mutationKey: ["create-member"],
    mutationFn: (payload: ICreateMember) => memberService.create(payload),
  });

export const useUpdateMember = () =>
  useMutation({
    mutationKey: ["update-member"],
    mutationFn: ({ id, payload }: TUpdateMemberParameter) => memberService.update({ id, payload }),
  });

export const useDeleteMember = () =>
  useMutation({
    mutationKey: ["delete-member"],
    mutationFn: (id: number) => memberService.delete(id),
  });
