import { ArticleList } from "~/components/article";
import { PAGE_ID, PATHS } from "~constants";
import { useGetPageList } from "~queries";

const ImpactList = () => {
  const { data } = useGetPageList(
    {
      limit: 50,
      filters: { parentPageId: PAGE_ID.IMPACT },
    },
    true,
  );

  return (
    <ArticleList
      createPath={PATHS.CREATE_IMPACT}
      detailPath={PATHS.IMPACT_DETAIL}
      pageOptions={data?.data}
      parentPageId={PAGE_ID.IMPACT}
    />
  );
};

export default ImpactList;
