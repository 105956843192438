import { IBaseEntity, IFile, IRquestParams } from "./common.type";
import { TCustomer } from "./customer.type";

export enum EFundType {
  FUNDING = 1,
  TICKETS,
}

export interface IFund extends IBaseEntity {
  value: string;
  currency: string;
  type: EFundType;
}

export interface ICreateDeal {
  title: string;
  tag: number;
  industry: string;
  area: string;
  description: string;
  thumbnailId: number;
  dealType: string;
  instrumentType: string;
  isSuccessful: boolean;
  isHighlight: boolean;
  isShowIpo: boolean;
  transferCustomerId: TCustomer["id"];
  acquisitionCustomerId: TCustomer["id"];
  transferReason: string;
  acquisitionPurpose: string;
  funding: IFund[];
  tickets: IFund[];
  ipoDate: string;
  ipoCompanyName: string;
  listingVenue: string;
  amountRaised: string;
  ipoDetails: string;
}

export interface IDeal extends ICreateDeal, IBaseEntity {
  thumbnail: IFile;
  transferCustomer: TCustomer;
  acquisitionCustomer: TCustomer;
  funds: IFund[];
}

export type TUpdateDealParameter = {
  id: number;
  payload: Partial<ICreateDeal>;
};

export type TQueryDeal = IRquestParams<{
  title?: string;
  isSuccessful?: boolean;
}>;
