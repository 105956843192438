import { Modal, ModalProps } from "antd";
import DealStatus from "~/components/deal-status";
import { DEAL_TAG } from "~constants";
import { IDeal } from "~types";
import { formatDate } from "~utils/dateHelper";
import { getLabelByValue } from "~utils/funcHelper";

interface IDealModalProps extends ModalProps {
  deal: IDeal;
}

const DealModal = ({ deal, ...props }: IDealModalProps) => {
  return (
    <Modal
      title="DEAL DETAIL"
      cancelText="Close"
      className="detail-modal"
      okButtonProps={{ className: "dis-none" }}
      width={800}
      {...props}
    >
      {deal.title && (
        <div className="detail__row">
          <div className="detail__label">Title</div>
          <span>{deal.title}</span>
        </div>
      )}

      <div className="detail__row">
        <div className="detail__label">Tag</div>
        <span>{getLabelByValue(DEAL_TAG, deal.tag)}</span>
      </div>

      <div className="detail__row">
        <div className="detail__label">Status</div>
        <span>
          <DealStatus status={deal.isSuccessful} />
        </span>
      </div>

      {deal.dealType && (
        <div className="detail__row">
          <div className="detail__label">Deal Type</div>
          <span>{deal.dealType}</span>
        </div>
      )}

      {deal.instrumentType && (
        <div className="detail__row">
          <div className="detail__label">Instrument Type</div>
          <span>{deal.instrumentType}</span>
        </div>
      )}

      {deal.area && (
        <div className="detail__row">
          <div className="detail__label">Area</div>
          <span>{deal.area}</span>
        </div>
      )}

      {deal.industry && (
        <div className="detail__row">
          <div className="detail__label">Industry</div>
          <span>{deal.industry}</span>
        </div>
      )}

      {deal.transferCustomer && (
        <div className="detail__row">
          <div className="detail__label">Transfer company</div>
          <span>{deal.transferCustomer?.name}</span>
        </div>
      )}

      {deal.acquisitionCustomer && (
        <div className="detail__row">
          <div className="detail__label">Acquisition Company</div>
          <span>{deal.acquisitionCustomer?.name}</span>
        </div>
      )}

      {deal.listingVenue && (
        <div className="detail__row">
          <div className="detail__label">IPO Listing Venue</div>
          <span>{deal.listingVenue}</span>
        </div>
      )}

      {deal.ipoDate && (
        <div className="detail__row">
          <div className="detail__label">IPO Date</div>
          <span>{formatDate(deal.ipoDate, "MM/YYYY")}</span>
        </div>
      )}

      {deal.amountRaised && (
        <div className="detail__row">
          <div className="detail__label">IPO Amount Raised</div>
          <span>{deal.amountRaised}</span>
        </div>
      )}

      {deal.ipoCompanyName && (
        <div className="detail__row">
          <div className="detail__label">IPO Company Name</div>
          <span>{deal.ipoCompanyName}</span>
        </div>
      )}
    </Modal>
  );
};

export default DealModal;
