import { ArticleForm } from "~/components/article";
import { PAGE_ID, PATHS } from "~constants";
import { useGetPageList } from "~queries";

const ImpactForm = () => {
  const { data } = useGetPageList(
    {
      limit: 50,
      filters: { parentPageId: PAGE_ID.IMPACT },
    },
    true,
  );

  return (
    <ArticleForm
      title="Impact Content"
      pageOptions={data?.data}
      navigation={{ pathname: PATHS.IMPACT, search: `tab=2` }}
    />
  );
};

export default ImpactForm;
