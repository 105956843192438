import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";

const queryCache = new QueryCache({
  onError: () => {
    // handle error here
  },
});

const mutationCache = new MutationCache({
  onError: () => {
    // handle error here
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      gcTime: 5 * 60 * 1000,
    },
    mutations: {},
  },
  queryCache,
  mutationCache,
});

export default queryClient;
