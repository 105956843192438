import request from "~libs/axios";
import { IResponse, IJob, ICreateJob, TUpdateJobParameter, TQueryJob } from "~types";

const END_POINT = "/jobs/";

const jobService = {
  getAll(params: TQueryJob): Promise<IResponse<IJob>> {
    return request.get(END_POINT, { params });
  },
  get(id: number) {
    return request.get(END_POINT + id);
  },
  create(payload: ICreateJob) {
    return request.post(END_POINT, payload);
  },
  update({ id, payload }: TUpdateJobParameter) {
    return request.patch(END_POINT + id, payload);
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default jobService;
