import { EJobType, EMediaType, ENewsType } from "~types";

export enum PAGE_ID {
  HOME = 1,
  OUR_DEAL,
  IMPACT,
  IMPACT_ENV,
  IMPACT_SOCIAL,
  IMPACT_GOVER,
  NEW,
  WHO_WE_ARE,
  BUSINESS,
  CONTACT_US,
  JOIN_US,
}

export const ESG_IMPACT = [
  {
    label: "Environmental",
    value: PAGE_ID.IMPACT_ENV,
  },
  {
    label: "Social",
    value: PAGE_ID.IMPACT_SOCIAL,
  },
  {
    label: "Governance",
    value: PAGE_ID.IMPACT_GOVER,
  },
];

export const DEAL_TAG = [
  { label: "Income", value: 0 },
  { label: "Growth", value: 1 },
];

export const DEAL_STATUS = [
  { label: "Live", value: 0 },
  { label: "Successful", value: 1 },
];

export const CORE_VALUE_FIELDS = [
  { name: "clientFocus", label: "Client Focus" },
  { name: "integrity", label: "Integrity" },
  { name: "excellence", label: "Excellence" },
  { name: "respect", label: "Respect" },
  { name: "diversity", label: "Diversity, Equity and Inclusion" },
  { name: "partnership", label: "Partnership" },
];

export const ACHIEVEMENT = [
  { label: "Employees & Partnerships", name: "employees" },
  { label: "Countries with Presence", name: "countries" },
  { label: "No. of Transactions at Group", name: "transactions" },
  { label: "Value of Transactions at Group", name: "transactionValue" },
];

export const NEWS_TYPE = [
  { label: "Timely Disclosure", value: ENewsType.TIMELY_DISCLOSURE },
  { label: "Financial Result", value: ENewsType.FINANCIAL_RESULT },
  { label: "Notification", value: ENewsType.NOTIFICATION },
];

export const NEWS_TYPE_COLOR = {
  [ENewsType.TIMELY_DISCLOSURE]: "#FF8A00",
  [ENewsType.FINANCIAL_RESULT]: "#0066FF",
  [ENewsType.NOTIFICATION]: "#0EAC00",
};

export const JOB_TYPE = [
  { label: "Full Time", value: EJobType.FULL_TIME },
  { label: "Part Time", value: EJobType.PART_TIME },
  { label: "Internship", value: EJobType.INTERNSHIP },
];

export const JOB_INDUSTRY_TYPE = [
  { label: "Sales", value: 0 },
  { label: "Technology", value: 1 },
  { label: "Finance", value: 2 },
  { label: "Marketing", value: 3 },
  { label: "HR", value: 4 },
];

export enum EFormType {
  SELLER,
  BUYER,
  CONTACT_US,
  JOIN_US,
}

export enum EInterestContent {
  BUSINESS_SUCCESSION,
  TRANSFER_COMPANY,
  CONSIDER_ACQUISITION,
}

export const OPTION_FORMTYPE = [
  {
    label: "Seller",
    value: 0,
  },
  {
    label: "Buyer",
    value: 1,
  },
  {
    label: "Contact us",
    value: 2,
  },
  {
    label: "Join us",
    value: 3,
  },
];

export const OPTION_INTEREST = [
  {
    label: "Business succession",
    value: 0,
  },
  {
    label: "Transfer company",
    value: 1,
  },
  {
    label: "Consider acquisition ",
    value: 2,
  },
];

export const MEDIA_TYPE = [
  { label: "Investment Banking", value: EMediaType.INVESTMENT },
  { label: "Equities", value: EMediaType.EQUITIES },
  { label: "Wealth", value: EMediaType.WEALTH },
  { label: "Firmwide", value: EMediaType.FIRMWIDE },
];
