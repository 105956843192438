import request from "~libs/axios";

const END_POINT = "/file/";

const headers = {
  "Content-Type": "multipart/form-data",
};

const fileService = {
  upload(formData: FormData): Promise<{ id: number }> {
    return request.post(END_POINT + "upload", formData, { headers });
  },

  uploadMultiple(formData: FormData) {
    return request.post(END_POINT + "upload-multiple", formData, { headers });
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default fileService;
