import { Col, Form, FormItemProps, Select, SelectProps } from "antd";
import clsx from "clsx";

interface IAInputProps extends SelectProps {
  span?: string | number;
  formItemProps?: FormItemProps;
}

const ASelect = ({ className, span, formItemProps, ...props }: IAInputProps) => {
  const selectClassName = clsx("a-select", className);

  return (
    <Col span={span}>
      <Form.Item {...formItemProps}>
        <Select allowClear className={selectClassName} {...props} />
      </Form.Item>
    </Col>
  );
};

export default ASelect;
