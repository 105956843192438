import { Form, Row } from "antd";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { useEffect, useState } from "react";
import { AButton, AInput, AUpload } from "~/components";
import { PAGE_ID } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useCreatePage, useGetPageByPageId, useUpdatePage } from "~queries";
import fileService from "~services/fileService";
import { IFile } from "~types";
import { getImgUrl } from "~utils/funcHelper";
import "./home.scss";

const Home = () => {
  const [form] = Form.useForm();
  const { message, showError } = useGlobal();
  const { mutate: mutateCreate } = useCreatePage(PAGE_ID.HOME);
  const { mutate: mutateUpdate } = useUpdatePage(PAGE_ID.HOME);
  const { data: pageHome } = useGetPageByPageId(PAGE_ID.HOME);
  const [deletedIds, setDeletedIds] = useState<number[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const isEdit = pageHome?.id ? true : false;
  const action = `${isEdit ? "Update" : "Create"}`;

  const onSuccess = async () => {
    message.success(`${action} successfully`);
    if (deletedIds.length) {
      const promiseDeleteList = deletedIds.map((id) => deleteFile(id));
      await Promise.all(promiseDeleteList);
      setDeletedIds([]);
    }
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const filterFiles = (files: IFile[]) => {
    if (!pageHome) return [];

    const allUploadedFiles = files.concat(pageHome.slider);
    const filteredFiles = allUploadedFiles.filter(({ id }) => !deletedIds.includes(id));

    return filteredFiles;
  };
  const onSubmit = async () => {
    form.validateFields().then(async (values) => {
      const slider = await uploadMultiFile();
      const { banner, ...payload } = values;
      payload.slider = slider;
      payload.pageId = PAGE_ID.HOME;

      if (isEdit) {
        payload.slider = filterFiles(payload.slider);
      }

      if (isEdit) {
        mutateUpdate({ id: pageHome?.id, payload }, { onSuccess, onError });
      } else {
        mutateCreate(payload, { onSuccess, onError });
      }
    });
  };

  const setValue = () => {
    if (!pageHome) {
      setFileList([]);
      return;
    }

    const newFileList = pageHome?.slider?.map((item) => ({
      uid: item.id.toString(),
      name: "",
      url: getImgUrl(item?.fileName),
      thumbUrl: getImgUrl(item?.fileName),
    }));

    setFileList(newFileList);

    form.setFieldsValue({
      banner: pageHome?.bannerImg?.id,
      title: pageHome?.title,
    });
  };

  useEffect(() => {
    setValue();
  }, [pageHome]);

  const fileListToFormData = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      file.originFileObj && formData.append("file", file.originFileObj as RcFile);
    });

    return formData;
  };

  const uploadMultiFile = async () => {
    const formData = fileListToFormData();
    try {
      setLoading(true);
      const data = await fileService.uploadMultiple(formData);
      return data;
    } catch (error) {
      message.error("Upload file filed");
      throw new Error("Upload file filed");
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = async (id: number) => {
    try {
      await fileService.delete(id);
    } catch (error) {
      message.error("Delete file filed");
      throw new Error("Delete file filed");
    }
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleRemoveFile = (file: UploadFile) => {
    if (!file?.originFileObj) {
      setDeletedIds((prev) => [...prev, Number(file.uid)]);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Row gutter={[20, 10]}>
        <AUpload
          span={24}
          formItemProps={{
            name: "banner",
            label: "Slider",
            rules: [{ required: true }],
          }}
          className="upload-multiple"
          listType="picture"
          multiple
          maxCount={10}
          onChange={handleChange}
          fileList={fileList}
          onRemove={handleRemoveFile}
        >
          <AButton iconType="upload" type="default" loading={loading}>
            Upload Multiple
          </AButton>
          <i className="ml-10">(upload up to 10 images)</i>
        </AUpload>
        <AInput
          span={12}
          type="textarea"
          formItemProps={{
            name: "title",
            label: "Title",
            rules: [{ required: true, max: 100 }],
          }}
          textAreaProps={{ rows: 4, placeholder: "Enter title" }}
        />
      </Row>
      <AButton htmlType="submit" className="mt-20" loading={loading}>
        Save
      </AButton>
    </Form>
  );
};

export default Home;
