import { Space, Tooltip } from "antd";
import AButton from "../common/a-button";

interface IActionTableProps {
  onEdit?: () => void;
  onDelete: () => void;
  onView?: () => void;
}

const ActionTable = ({ onEdit, onDelete, onView }: IActionTableProps) => {
  return (
    <Space>
      {onView && (
        <Tooltip title="View">
          <>
            <AButton iconType="view" onClick={onView} type="default" />
          </>
        </Tooltip>
      )}
      {onEdit && (
        <Tooltip title="Edit">
          <>
            <AButton iconType="edit" onClick={onEdit} />
          </>
        </Tooltip>
      )}

      <Tooltip title="Delete">
        <>
          <AButton iconType="delete" danger onClick={onDelete} />
        </>
      </Tooltip>
    </Space>
  );
};

export default ActionTable;
