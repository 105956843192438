import { Tabs, TabsProps } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

const ATabs = ({ ...props }: TabsProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const onChange = (key: string) => navigate({ search: `tab=${key}` });

  return <Tabs defaultActiveKey={search.slice(-1)} onChange={onChange} {...props} />;
};

export default ATabs;
