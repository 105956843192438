import { Col, Form, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { To, useNavigate, useParams } from "react-router-dom";
import { AButton, AInput } from "~/components";
import ASelect from "~/components/common/a-select";
import TextEditor from "~/components/text-editor";
import useGlobal from "~hooks/useGlobal";
import { useCreateArticle, useGetArticle, useUpdateArticle } from "~queries";

interface IArticleFormProps {
  title: string;
  pageOptions?: Record<string, any>[];
  navigation: To;
}

const ArticleForm = ({ title, pageOptions, navigation }: IArticleFormProps) => {
  const navigate = useNavigate();
  const { message, showError } = useGlobal();
  const { id } = useParams();
  const articleId = Number(id);
  const [form] = Form.useForm();
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);
  const { mutate: mutateCreate } = useCreateArticle();
  const { mutate: mutateUpdate } = useUpdateArticle();
  const { data: article } = useGetArticle(articleId, { enabled: !!articleId });
  const isEdit = article && Object.keys(article).length > 0;
  const action = `${isEdit ? "Update " : "Create New "}`;

  const backToList = () => navigate(navigation);

  const onSuccess = () => {
    message.success(`${action} successfully`);
    backToList();
  };

  const onError = (error: Error) => showError(error, `${action} failed`);

  const onSubmit = async () => {
    if (!content) {
      setContentError(true);
      return;
    }
    form.validateFields().then((values) => {
      const payload = { ...values, content };
      if (isEdit) {
        mutateUpdate({ id: articleId, payload }, { onSuccess, onError });
      } else {
        mutateCreate(payload, { onSuccess, onError });
      }
    });
  };

  const onEditorChange = (value: string) => {
    if (value) {
      setContentError(false);
    } else {
      setContentError(true);
    }
    setContent(value);
  };

  const setValue = () => {
    if (isEdit) {
      const initialValues = {
        title: article?.title,
        pageId: article?.pageId,
      };
      form.setFieldsValue(initialValues);
      setContent(article?.content || "");
    }
  };

  useEffect(() => {
    setValue();
  }, [article, isEdit]);

  return (
    <Form form={form} layout="vertical">
      <h2 className="mb-20">
        {action} {title}
      </h2>
      <Row gutter={[20, 10]}>
        <AInput
          span={6}
          placeholder="Enter title"
          formItemProps={{ name: "title", label: "Title", rules: [{ required: true }] }}
        />
        <ASelect
          options={pageOptions}
          span={6}
          formItemProps={{
            name: "pageId",
            label: "Page",
            rules: [{ required: true }],
          }}
          placeholder="Select a page"
        />
        <Col span={24}>
          <Form.Item label="Content" required>
            <TextEditor
              value={content}
              onChange={onEditorChange}
              isError={contentError}
              label="content"
            />
          </Form.Item>
        </Col>
      </Row>
      <Space>
        <AButton htmlType="submit" onClick={onSubmit}>
          Save
        </AButton>
        <AButton type="default" onClick={backToList}>
          Cancel
        </AButton>
      </Space>
    </Form>
  );
};

export default ArticleForm;
