import { App, ConfigProvider } from "antd";
import { ReactNode } from "react";
import "../../shared/styles/_font.scss";
import form from "./formConfig";
import theme from "./themeConfig";

const AntdProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ConfigProvider
      theme={theme}
      form={form}
      componentSize="large"
      locale={{ locale: "en", Image: { preview: "" } }}
    >
      <App>{children}</App>
    </ConfigProvider>
  );
};

export default AntdProvider;
