import { useMutation, useQuery } from "@tanstack/react-query";
import articleService from "~services/articleService";
import {
  ICreateArticle,
  IArticle,
  TQueryArticle,
  TQueryOptions,
  TUpdateArticleParameter,
} from "~types";

export const useGetArticles = (params: TQueryArticle) =>
  useQuery({
    queryKey: ["get-articles", params],
    queryFn: () => articleService.getAll(params),
  });

export const useGetArticle = (id: number, options?: TQueryOptions<IArticle>) =>
  useQuery({
    queryKey: ["get-article", id],
    queryFn: () => articleService.get(id),
    ...options,
  });

export const useCreateArticle = () =>
  useMutation({
    mutationKey: ["create-article"],
    mutationFn: (payload: ICreateArticle) => articleService.create(payload),
  });

export const useUpdateArticle = () =>
  useMutation({
    mutationKey: ["update-article"],
    mutationFn: ({ id, payload }: TUpdateArticleParameter) =>
      articleService.update({ id, payload }),
  });

export const useDeleteArticle = () =>
  useMutation({
    mutationKey: ["delete-article"],
    mutationFn: (id: number) => articleService.delete(id),
  });
