import { TabsProps } from "antd";
import { ATabs } from "~/components";
import DealGeneral from "./deal-general";
import DealList from "./deal-list";

const DealManagement = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General",
      children: <DealGeneral />,
    },
    {
      key: "2",
      label: "Deal Management",
      children: <DealList />,
    },
  ];

  return <ATabs items={items} />;
};

export default DealManagement;
