import { Flex, Form, Row, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AButton, AInput, ASelect } from "~/components";
import ActionTable from "~/components/action-table/action-table";
import useGlobal from "~hooks/useGlobal";
import { useDeleteArticle, useGetArticles } from "~queries";
import { IArticle } from "~types";
import { formatDate } from "~utils/dateHelper";
import { replaceIdWithValue } from "~utils/funcHelper";

interface IArticleListProps {
  createPath: string;
  detailPath: string;
  pageOptions?: Record<string, any>[];
  parentPageId: number;
}

const ArticleList = ({ createPath, detailPath, pageOptions, parentPageId }: IArticleListProps) => {
  const navigate = useNavigate();
  const { confirmDelete, message } = useGlobal();
  const [filters, setFilters] = useState({ parentPageId });
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data, refetch } = useGetArticles({ ...pagination, filters });
  const { mutate } = useDeleteArticle();

  const onSearch = (key: string, value: string | number) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onCreate = () => {
    navigate(createPath);
  };

  const onEdit = (id: number) => {
    const url = replaceIdWithValue(detailPath, id);
    navigate(url);
  };
  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          refetch();
        },
        onError: () => {
          message.error("Delete failed");
        },
      });
    }
  };

  const columns: ColumnType<IArticle>[] = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Page",
      dataIndex: "pageTitle",
      render: (value) => value,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (value) => formatDate(value),
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => (
        <ActionTable onEdit={() => onEdit(record.id)} onDelete={() => onDelete(record.id)} />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} justify="space-between" align="center">
        <Form layout="vertical" className="w--90">
          <Row gutter={[10, 10]}>
            <ASelect
              options={pageOptions}
              span={5}
              formItemProps={{ label: "Page" }}
              placeholder="Select a page"
              onChange={(value) => onSearch("pageId", value)}
            />
            <AInput
              span={6}
              placeholder="Search by title"
              type="search"
              formItemProps={{ label: "Title" }}
              onSearch={(value) => onSearch("title", value)}
            />
          </Row>
        </Form>
        <AButton iconType="add" onClick={onCreate}>
          Create
        </AButton>
      </Flex>
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default ArticleList;
