import { Layout } from "antd";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Header, Loading, SideBar } from "~/components";

const MainLayout = () => {
  return (
    <Layout className="min-h-screen">
      <SideBar />
      <Layout className="mt-64">
        <Header />
        <Layout.Content className="ma-15 pa-20 ml-295 h-full bg-white">
          <Suspense fallback={<Loading show />}>
            <Outlet />
          </Suspense>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
