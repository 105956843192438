import type { InternalAxiosRequestConfig } from "axios";
import { getAccessToken } from "~utils/funcHelper";

const requestHandler = {
  success: (config: InternalAxiosRequestConfig<unknown>) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error: (error: unknown) => {
    return Promise.reject(error);
  },
};

export default requestHandler;
