import request from "~libs/axios";
import { ICreateMember, IResponse, IMember, TQueryMember, TUpdateMemberParameter } from "~types";

const END_POINT = "/members/";

const memberService = {
  getAll(params: TQueryMember): Promise<IResponse<IMember>> {
    return request.get(END_POINT, { params });
  },
  get(id: number) {
    return request.get(END_POINT + id);
  },
  create(payload: ICreateMember) {
    return request.post(END_POINT, payload);
  },
  update({ id, payload }: TUpdateMemberParameter) {
    return request.patch(END_POINT + id, payload);
  },
  delete(id: number) {
    return request.delete(END_POINT + id);
  },
};

export default memberService;
