import { IBaseEntity, IFile, IRquestParams } from "./common.type";

export enum ENewsType {
  TIMELY_DISCLOSURE = 1,
  FINANCIAL_RESULT,
  NOTIFICATION,
}

export interface ICreateNews extends IBaseEntity {
  title: string;
  type: ENewsType;
  fileId: number;
}

export interface INews extends ICreateNews, IBaseEntity {
  file: IFile;
}

export type TUpdateNewsParameter = {
  id: number;
  payload: Partial<ICreateNews>;
};

export type TQueryNews = IRquestParams<{
  title?: string;
  type?: ENewsType;
}>;
