export const localCache = {
  set(key: string, value: string) {
    return localStorage.setItem(key, value);
  },
  get(key: string) {
    return localStorage.getItem(key);
  },
  setJson(key: string, valueJson: object) {
    return localStorage.setItem(key, JSON.stringify(valueJson));
  },
  getJson<T>(key: string) {
    const stringValue = localStorage.getItem(key) ?? "";
    if (stringValue) {
      return JSON.parse(stringValue) as T;
    }
    return null;
  },
  remove(key: string) {
    return localStorage.removeItem(key);
  },
};
