// URL
export const BASE_URL = import.meta.env.VITE_BASE_URL || "";
export const HOST = import.meta.env.VITE_HOST || "";
export const TINY_MCE_API_KEY = import.meta.env.VITE_TINY_MCE_API_KEY || "";

// Key
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const USER_INFO = "userInfo";

// Date
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_API = "YYYY-MM-DD";

// File
export const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
export const MAX_UPLOAD_SIZE = 10 * 1024 * 1024;
