import { useMutation } from "@tanstack/react-query";
import authService from "~services/authService";
import { ICredentials } from "~types";

export const useLogIn = () =>
  useMutation({
    mutationKey: ["login"],
    mutationFn: (credentials: ICredentials) => authService.login(credentials),
  });

export const useForgotPassword = () =>
  useMutation({
    mutationKey: ["change-password"],
    mutationFn: () => authService.forgotPassword(),
  });
