import { Flex, Form, Row, Switch, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AButton, AInput, ASelect } from "~/components";
import ActionTable from "~/components/action-table";
import DealStatus from "~/components/deal-status";
import { DEAL_STATUS, DEAL_TAG, PATHS } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useDeleteDeal, useGetDeals, useUpdateDeal } from "~queries";
import { IDeal } from "~types";
import { getLabelByValue, replaceIdWithValue } from "~utils/funcHelper";
import DealModal from "./deal-modal";

const DealList = () => {
  const navigate = useNavigate();
  const { confirmDelete, message } = useGlobal();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data, refetch } = useGetDeals({ ...pagination, filters });
  const { mutate } = useDeleteDeal();
  const { mutate: mutateUpdate } = useUpdateDeal();
  const [openModal, setOpenModal] = useState(false);
  const [deal, setDeal] = useState<IDeal>({} as IDeal);

  const onSearch = (key: string, value: string | number | boolean) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onCreate = () => navigate(PATHS.CREATE_DEAL);

  const onEdit = (id: number) => {
    const url = replaceIdWithValue(PATHS.DEAL_DETAIL, id);
    navigate(url);
  };

  const onView = (deal: IDeal) => {
    setOpenModal(true);
    setDeal(deal);
  };
  const onDelete = async (id: number) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      mutate(id, {
        onSuccess: () => {
          message.success("Delete deal successfully");
          if (data?.data?.length === 1 && pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
          }
          refetch();
        },
        onError: () => {
          message.error("Delete deal failed");
        },
      });
    }
  };

  const handleHighlight = (isHighlight: boolean, record: IDeal) => {
    const payload = {
      transferCustomerId: record?.transferCustomer?.id,
      thumbnailId: record?.thumbnail?.id,
      isHighlight,
    };
    mutateUpdate(
      { id: record.id, payload },
      {
        onSuccess: () => {
          message.success("Update deal successfully");
          refetch();
        },
        onError: () => {
          message.error("Update deal failed");
        },
      },
    );
  };

  const columns: ColumnType<IDeal>[] = [
    {
      title: "Title",
      dataIndex: "title",
      width: 500,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "isSuccessful",
      align: "center",
      width: 150,
      render: (value) => <DealStatus status={value} />,
    },
    {
      title: "Instrument Type",
      dataIndex: "instrumentType",
      align: "center",
      width: 200,
    },
    {
      title: "Deal Type",
      dataIndex: "dealType",
      align: "center",
      width: 150,
    },
    {
      title: "Tag",
      dataIndex: "tag",
      align: "center",
      width: 150,
      render: (value) => getLabelByValue(DEAL_TAG, value),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      align: "center",
      width: 300,
    },
    {
      title: "Description",
      dataIndex: "description",
      ellipsis: true,
      width: 400,
    },
    {
      title: "Highlight",
      dataIndex: "isHighlight",
      align: "center",
      fixed: "right",
      width: 125,
      render: (value, record) => (
        <Switch checked={value} onChange={(value) => handleHighlight(value, record)} />
      ),
    },
    {
      title: "Actions",
      fixed: "right",
      align: "center",
      width: 160,
      render: (_, record) => (
        <ActionTable
          onView={() => onView(record)}
          onEdit={() => onEdit(record.id)}
          onDelete={() => onDelete(record.id)}
        />
      ),
    },
  ];

  return (
    <>
      <Flex justify="space-between" align="center">
        <Form layout="vertical" className="w--90">
          <Row gutter={[10, 10]}>
            <ASelect
              span={5}
              options={DEAL_STATUS}
              placeholder="Select deal status"
              formItemProps={{ label: "Status" }}
              onChange={(value) =>
                onSearch("isSuccessful", [0, 1].includes(value) ? Boolean(value) : value)
              }
            />
            <AInput
              span={8}
              placeholder="Search deal by title"
              formItemProps={{ label: "Title" }}
              type="search"
              onSearch={(value) => onSearch("title", value)}
            />
          </Row>
        </Form>
        <AButton iconType="add" onClick={onCreate}>
          Create
        </AButton>
      </Flex>
      <DealModal open={openModal} onCancel={() => setOpenModal(false)} deal={deal} />
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        scroll={{ x: 2000 }}
        pagination={{
          total: data?.total,
          current: pagination.page,
          pageSize: pagination.limit,
          onChange: (page, limit) => setPagination({ page, limit }),
        }}
      />
    </>
  );
};

export default DealList;
