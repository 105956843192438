import { IBaseEntity, IRquestParams } from "./common.type";

export enum EJobType {
  FULL_TIME,
  PART_TIME,
  INTERNSHIP,
}

export enum EJobIndustryType {
  SALES,
  TECHNOLOGY,
  FINANCE,
  MARKETING,
  HR,
}

export interface ICreateJob {
  title: string;
  type: EJobType;
  industryType: EJobIndustryType;
  description: string;
  location: string;
  salaryRange: string;
  requirements: string;
  responsibilities: string;
}

export interface IJob extends IBaseEntity, ICreateJob {}

export type TUpdateJobParameter = {
  id: number;
  payload: Partial<ICreateJob>;
};

export type TQueryJob = IRquestParams<{
  title?: string;
  type?: EJobType;
}>;
