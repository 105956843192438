import { useMutation, useQuery } from "@tanstack/react-query";
import { Params } from "react-router-dom";
import dealService from "~services/dealService";
import { ICreateDeal, TQueryDeal, TUpdateDealParameter } from "~types";

export const useGetDeals = (params: TQueryDeal) =>
  useQuery({
    queryKey: ["get-deals", params],
    queryFn: () => dealService.getAll(params),
  });

export const useGetDeal = (params: Readonly<Params<string>>) => {
  const dealId = Number(params.id);
  const isNumber = isNaN(dealId);
  return useQuery({
    queryKey: ["get-deal", params],
    queryFn: () => dealService.get(dealId),
    enabled: !isNumber,
  });
};

export const useCreateDeal = () =>
  useMutation({
    mutationKey: ["create-deal"],
    mutationFn: (payload: ICreateDeal) => dealService.create(payload),
  });

export const useUpdateDeal = () =>
  useMutation({
    mutationKey: ["update-deal"],
    mutationFn: ({ id, payload }: TUpdateDealParameter) => dealService.update({ id, payload }),
  });

export const useDeleteDeal = () =>
  useMutation({
    mutationKey: ["delete-deal"],
    mutationFn: (id: number) => dealService.delete(id),
  });
