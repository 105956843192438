import { Form, Modal, ModalProps } from "antd";
import { AInput, ASelect } from "~/components";
import { OPTION_FORMTYPE, OPTION_INTEREST } from "~constants";
import useGlobal from "~hooks/useGlobal";
import { useUpdateFormSubmit } from "~queries";
import { IFormSubmit } from "~types";

interface ISubmitModalProps extends ModalProps {
  submitInfo: IFormSubmit;
  onCancel: () => void;
  refetch: any;
  isView?: boolean;
}

const ModalDetail = ({ submitInfo, onCancel, refetch, isView, ...props }: ISubmitModalProps) => {
  const [form] = Form.useForm();
  const { mutate } = useUpdateFormSubmit();
  const { message } = useGlobal();

  const onSubmit = () => {
    form.validateFields().then((payload) => {
      mutate(
        { payload, id: submitInfo.id },
        {
          onSuccess: () => {
            message.success("Update successfully");
            onCancel();
            refetch();
          },
          onError: () => {
            message.error("Update failed");
            onCancel();
          },
        },
      );
    });
  };

  return (
    <>
      {isView ? (
        <Modal
          title="Submit Information"
          cancelText="Close"
          className="detail-modal"
          okButtonProps={{ className: "dis-none" }}
          width={700}
          centered
          onCancel={onCancel}
          {...props}
        >
          <div>
            <div className="detail__row">
              <div className="detail__label">Company Name</div>
              <div>{submitInfo.companyName}</div>
            </div>

            <div className="detail__row">
              <div className="detail__label">Phone Number</div>
              <span>{submitInfo.phoneNumber}</span>
            </div>

            <div className="detail__row">
              <div className="detail__label">Email</div>
              <span>{submitInfo.email}</span>
            </div>

            <div className="detail__row">
              <div className="detail__label">Type</div>
              <span>{OPTION_FORMTYPE[submitInfo?.formType]?.label}</span>
            </div>

            <div className="detail__row">
              <div className="detail__label">Inquiry</div>
              <span>{submitInfo.inquiry}</span>
            </div>

            <div className="detail__row">
              <div className="detail__label">Interest</div>
              <span>{OPTION_INTEREST[submitInfo?.interestContent]?.label}</span>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal title={"Update contact"} onOk={onSubmit} onCancel={onCancel} {...props} width={600}>
          <Form layout="vertical" form={form} initialValues={submitInfo}>
            <AInput
              placeholder="Enter company name"
              formItemProps={{ name: "companyName", label: "Company", rules: [{ required: true }] }}
            />
            <AInput
              placeholder="Enter phone number"
              formItemProps={{ name: "phoneNumber", label: "Phone", rules: [{ required: true }] }}
            />
            <AInput
              placeholder="Enter email"
              formItemProps={{ name: "email", label: "Email", rules: [{ required: true }] }}
            />

            <AInput
              type="textarea"
              formItemProps={{ name: "inquiry", label: "Inquiry", rules: [{ required: true }] }}
              textAreaProps={{ rows: 2, placeholder: "Enter inquiry" }}
            />

            <ASelect
              options={OPTION_INTEREST}
              placeholder="Select interest content"
              formItemProps={{
                name: "interestContent",
                label: "Interest",
                rules: [{ required: true }],
              }}
            />

            <ASelect
              options={OPTION_FORMTYPE}
              placeholder="Select page to edit"
              formItemProps={{
                name: "formType",
                label: "Select type",
                rules: [{ required: true }],
              }}
            />
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ModalDetail;
