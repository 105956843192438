import { UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import { useState } from "react";
import fileService from "~services/fileService";

const useUploadFile = () => {
  const [fileId, setFileId] = useState<number | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);

  const uploadFile = async (file: RcFile) => {
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    try {
      setLoading(true);
      const { id } = await fileService.upload(formData);
      setFileId(id);
    } catch (error) {
      throw new Error("Upload file filed");
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = async () => {
    try {
      if (!fileId) return;
      await fileService.delete(fileId);
      setFileId(null);
    } catch (error) {
      throw new Error("Delete file filed");
    }
  };

  const handleUploadChange = (
    file: RcFile | UploadFile,
    newFileList: UploadFile[],
    isEdit: boolean = false,
  ) => {
    if (newFileList?.length) {
      uploadFile(file as RcFile);
    } else if (!isEdit) {
      deleteFile();
    }
    setFileList(newFileList);
  };

  return { fileId, setFileId, fileList, setFileList, handleUploadChange, loading, deleteFile };
};

export default useUploadFile;
