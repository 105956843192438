import { Col, Form, FormItemProps, Input, InputNumber, InputNumberProps } from "antd";
import clsx from "clsx";
import { SearchProps, TextAreaProps } from "antd/es/input";

interface IAInputProps extends SearchProps {
  span?: string | number;
  offset?: string | number;
  formItemProps?: FormItemProps;
  textAreaProps?: TextAreaProps;
  inputNumberProps?: InputNumberProps;
}

const AInput = ({
  className,
  span,
  offset,
  formItemProps,
  textAreaProps,
  inputNumberProps,
  ...props
}: IAInputProps) => {
  const inputClassName = clsx(
    "a-input",
    {
      "w-400": props.type === "search",
    },
    className,
  );

  const inputProps = {
    allowClear: true,
    className: inputClassName,
    ...props,
  };

  let element = <Input {...inputProps} />;

  switch (props.type) {
    case "search":
      element = <Input.Search {...inputProps} />;
      break;
    case "textarea":
      element = <Input.TextArea allowClear {...textAreaProps} />;
      break;
    case "number":
      element = <InputNumber className="w-full" min={0} {...inputNumberProps} />;
      break;
    default:
      break;
  }

  return (
    <Col span={span} offset={offset}>
      <Form.Item {...formItemProps}>{element}</Form.Item>
    </Col>
  );
};

export default AInput;
