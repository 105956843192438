import { useMutation, useQuery } from "@tanstack/react-query";
import pageService from "~services/pageService";
import { ICreatePage, IUpdatePage, TQueryPage } from "~types";
import { getOptions } from "~utils/funcHelper";

export const useGetPageList = (params: TQueryPage, isGetOption: boolean = false) =>
  useQuery({
    queryKey: ["get-page-list", params],
    queryFn: () => pageService.getAll(params),
    select: (response) => {
      if (isGetOption) {
        return { ...response, data: getOptions("title", response.data) };
      }
      return response;
    },
  });

export const useGetPageByPageId = (pageId: number) =>
  useQuery({
    queryKey: ["get-page", pageId],
    queryFn: () => pageService.getByPageId(pageId),
  });

export const useCreatePage = (pageId: number) =>
  useMutation({
    mutationKey: ["create-page", { pageId }],
    mutationFn: (payload: ICreatePage) => pageService.create(payload),
  });

export const useUpdatePage = (pageId: number) =>
  useMutation({
    mutationKey: ["update-page", { pageId }],
    mutationFn: ({ id, payload }: IUpdatePage) => pageService.update({ id, payload }),
  });

export const useDeletePage = () =>
  useMutation({
    mutationKey: ["delete-page"],
    mutationFn: (id: number) => pageService.delete(id),
  });
